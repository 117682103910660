import { io } from 'socket.io-client';
const serverBaseUrl = (process.env.REACT_APP_SERVER_BASE_URL).trim();
const axios = require('axios');
const swal = require('sweetalert');
var currentTime = Date.now();
setInterval(() => {
    currentTime = Date.now();
}, 1000);
console.log("process.env.REACT_APP_SERVER_BASE_URL",process.env.REACT_APP_SERVER_BASE_URL)

if ('Notification' in window) {
    if (Notification.permission === 'default' || Notification.permission === 'denied') {
        Notification.requestPermission();
    }
}

class Constants {
    depositTypes = { '0': 'UPI', '1': 'Stripe', '2': 'PayUMoney', '3': 'PayPal', '4': 'Direct Deposit' }
    defaultImage(event) {
        event.target.setAttribute('src', (event.target.hasAttribute('data-src')) ? event.target.getAttribute('data-src') : '/assets/images/404-not-found.png')
    }
    getTimeAgo(timestamp) {
        const date = new Date(timestamp);
        const timeIs = date.getTime();
        const diff = currentTime - timeIs;
        const minute = 60 * 1000;
        const hour = 60 * minute;
        const day = 24 * hour;
        const month = (new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() * day);

        if (diff < minute) {
            return 'just now';
        } else if (diff < hour) {
            const minutes = Math.floor(diff / minute);
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else if (diff < day) {
            const hours = Math.floor(diff / hour);
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (diff < month) {
            const days = Math.floor(diff / day);
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else {
            return date.toDateString();
        }
    }
    notifify(message, data = {}) {
        if ('Notification' in window) {
            if (Notification.permission === 'granted') {
                return new Notification("Earn Tex", {
                    body: message,
                    icon: window.location.origin + '/logo.png',
                    tag: data,
                });
            }
        }
    }
    createElement(selector = 'input', options = { attributes: { name: 'txnId', type: 'text', className: 'swal-content__input' } }) {
        var element = document.createElement(selector);
        if (options.attributes !== undefined) {
            for (var attribute in options.attributes) {
                element.setAttribute(attribute, options.attributes[attribute]);
            }
        }
        if (options.className !== undefined) {
            for (var className of options.className) {
                element.classList.add(className)
            }
        }
        return element;
    }
    changeStatus(url, data, message, setFilterData, filterAction = 'change', options = null) {
        swal({
            title: "Are you sure?",
            text: message,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmed) => {
                if (confirmed) {
                    if (options !== null) {
                        swal({
                            text: options.text,
                            content: options.content,
                            button: {
                                text: options.buttonText,
                                closeModal: false,
                            },
                        }).then(result => {
                            if (result) {
                                var inputs = options.content.querySelectorAll('[name]');
                                inputs.forEach((ele) => {
                                    if (ele.hasAttribute('required') && ele.value.trim() === '') {
                                        var showMessage = ele.getAttribute('name') + " is required.";
                                        if (ele.hasAttribute('data-message')) {
                                            showMessage = ele.getAttribute('data-message');
                                        }
                                        swal(showMessage, { icon: "error" });
                                        return;
                                    }
                                    data[ele.getAttribute('name')] = ele.value.trim();
                                });
                                this.postAndFilter(url, setFilterData, data, filterAction);
                                inputs.forEach((ele) => {
                                    ele.value = '';
                                });
                            }
                        });
                    }
                    else {
                        this.postAndFilter(url, setFilterData, data, filterAction);
                    }
                }
            });
    }
    authToken() {
        const tokenData = {};
        const authToken = localStorage.getItem('authToken');
        if (authToken !== undefined && authToken !== null && authToken.trim() !== '')
            tokenData.authToken = authToken;
        return tokenData;
    }
    async axiosRequest(url, type, reqData = {}) {
        var data = JSON.stringify(reqData);
        var queryString = '';

        if (type === 'get') {
            queryString = new URLSearchParams(reqData).toString()
        }

        var config = {
            method: type,
            maxBodyLength: Infinity,
            url: serverBaseUrl + url + ((queryString !== '') ? '?' + queryString : ''),
            headers: {
                'Content-Type': 'application/json',
                ...this.authToken(),
            },
            data: data
        };

        return await axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error;
            });
    }
    async multiPart(url, formData) {
        return await axios.post(serverBaseUrl + url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...this.authToken(),
            },
        }).then(function (response) {
            return response.data;
        }).catch(function (error) {
            return error;
        });
    }
    async serverPost(url, data = {}) {
        return await this.axiosRequest(url, 'post', data);
    }
    async serverPut(url, data = {}) {
        return await this.axiosRequest(url, 'put', data);
    }
    async serverGet(url, data = {}) {
        return await this.axiosRequest(url, 'get', data);
    }
    postAndFilter(url, setFilterData, data = {}, action = 'remove') {
        this.axiosRequest(url, 'post', data).then((res) => {
            if (res.success === '1') {
                setFilterData({ process: true, data, action });
                swal(res.message, {
                    icon: "success",
                });
            }
            else {
                swal(res.message, {
                    icon: "error",
                });
            }
        });
    }
    formData(data) {
        const formData = new FormData();
        for (const value in data) {
            formData.append(value, data[value]);
        }
        return formData;
    }
    socketIo() {
        const authToken = this.authToken().authToken;
        return io(serverBaseUrl, {
            path: '/websocket/',
            transports: ["websocket", "polling"],
            query: new URLSearchParams({ authToken }).toString(),
            extraHeaders: { authToken },
            auth: { authToken }
        })
    }
    dataSocketIo() {
        return io(serverBaseUrl, {
            path: '/websocket/',
            transports: ["websocket", "polling"],
        })
    }
    forexSocket() {
        return new WebSocket('wss://socket.polygon.io/forex');
    }
    reload() {
        window.location.href = window.location.href;
    }
}

export default new Constants();
