import React, { useContext, useEffect, useState, useRef, memo } from 'react';
import { AuthContext } from '../../helper/AuthContext';
import NoChatHead from '../../components/NoChatHead';
import constants from '../../helper/Constants';
import { SocketContext } from '../../helper/SocketContext';
import { useSearchParams } from 'react-router-dom';
import ChatUsers from '../../components/ChatUsers';
import ChatMessages from '../../components/ChatMessages';
import ChatHead from '../../components/ChatHead';
import swal from 'sweetalert';
import ChatMessageBox from '../../components/ChatMessageBox';
import BreadCrumb from '../../components/BreadCrumb';
function Chat() {
    const [usersList, setUsersList] = useState([]);
    const [userSearchList, setUserSearchList] = useState([]);
    const [joinRooms, setJoinRoom] = useState(false);
    const [allRooms, setAllRooms] = useState([]);
    const [dataItems, setDataItems] = useState([]);
    const [chatHead, setChatHead] = useState(null);
    const [searchInputInit, setSearchInputInit] = useState([]);
    const initialPageLoaded = useRef(false);
    const [searchParams] = useSearchParams();
    const { userId, name, userName, profilePic } = useContext(AuthContext);
    const { socket, pendingEmit, setPendingEmit, newMessage, setNewMessage } = useContext(SocketContext);

    useEffect(() => {
        constants.serverGet('chat-message/get-live-rooms').then((res) => {
            initialPageLoaded.current = true;
            setUsersList(res.data ?? []);
            setAllRooms(res.data.map((data) => {
                return data.roomId;
            }));
            if (searchParams.get("room") !== null) {
                res.data.map((roomData) => {
                    if (searchParams.get("room") === roomData.roomId) {
                        openChatHead(roomData);
                    }
                });
            }
        });
    }, []);

    useEffect(() => {
        if (newMessage !== null) {
            if (newMessage.roomId === joinRooms) {
                setDataItems([...dataItems, newMessage]);
            }
            if (!allRooms.includes(newMessage.roomId)) {
                newMessage.unReaded = 1;
                setUsersList([newMessage, ...usersList]);
                setAllRooms([...allRooms, newMessage.roomId]);
            }
            else {
                let filterUsers = usersList.map((data) => {
                    if (data.roomId === newMessage.roomId) {
                        let unReaded = 0;
                        if (chatHead) {
                            if (chatHead.roomId !== data.roomId) {
                                if (userId == data.receiverId) {
                                    unReaded = parseInt(data.unReaded) + 1
                                }
                            }
                        }
                        else if (userId == data.receiverId) {
                            unReaded = parseInt(data.unReaded) + 1
                        }
                        return { ...newMessage, unReaded };
                    }
                    else {
                        return data;
                    }
                });
                filterUsers.sort((a, b) => {
                    return b.id - a.id;
                });
                setUsersList(filterUsers);
            }
            setNewMessage(null);
        }
    }, [newMessage]);

    const getOldMessages = (roomId) => {
        constants.serverPost('chat-message', { roomId }).then((res) => {
            setDataItems(res.data);
        });
    }

    const emitMessage = (event, message) => {
        if (socket.emit !== undefined) {
            socket.emit(event, message);
        }
        else {
            setPendingEmit([...pendingEmit, { event, message }]);
        }
    }

    const openChatHead = (userItem) => {
        getOldMessages(userItem.roomId);
        setJoinRoom(userItem.roomId);
        setDataItems([]);
        setChatHead(userItem);
    }

    const sendMessage = (data, { setSubmitting, resetForm }) => {
        if (data.message.trim() === "") {
            swal.error('Please enter message');
            setSubmitting(false);
        }
        else {
            emitMessage('chatBot', {
                receiverId: (chatHead.receiverId !== userId) ? chatHead.receiverId : chatHead.senderId,
                senderId: userId,
                roomId: chatHead.roomId,
                message: data.message,
                mimeType: "text/plain",
            });
            resetForm();
            setSubmitting(false);
        }
    }

    const searchUser = (data) => {
        var inputValue = (data.target.value.toString()).trim();
        if (inputValue !== '') {
            setSearchInputInit(true);
            const regex = new RegExp(inputValue, "i");
            setUserSearchList(usersList.filter((data) => {
                if (data.receiverId !== userId) {
                    if (regex.test(data['receiver.userName']) || regex.test(data['receiver.name'])) {
                        return data;
                    }
                }
                else {
                    if (regex.test(data['sender.userName']) || regex.test(data['sender.name'])) {
                        return data;
                    }
                }
            }));
        }
        else {
            setSearchInputInit(false);
        }
    }

    return (
        <>
            <BreadCrumb title={'Chat App'} />

            <div className="row chatbox">
                <ChatUsers
                    usersList={(searchInputInit === true) ? userSearchList : usersList}
                    openChatHead={openChatHead}
                    searchUser={searchUser} />
                <div className="col-md-12 col-lg-7 col-xl-8 chat">
                    <div className="card">
                        {
                            (chatHead !== null) ? (
                                <>
                                    <ChatHead chatHead={chatHead} />
                                    <ChatMessages dataItems={dataItems} chatHead={chatHead} />
                                    <ChatMessageBox sendMessage={sendMessage} />
                                </>
                            ) : (
                                <>
                                    <NoChatHead />
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(Chat);