import { memo, useContext } from "react";
import { AuthContext } from "../helper/AuthContext";
import constants from "../helper/Constants";
function ReceiverMessage({ chatItem, chatHead }) {
    const { userId } = useContext(AuthContext);
    return (
        <div className="d-flex justify-content-start">
            <div className="img_cont_msg">
                <img src={(userId !== chatHead.senderId) ?process.env.REACT_APP_SERVER_BASE_URL+"uploads/"+ chatHead['sender.profilePic'] : process.env.REACT_APP_SERVER_BASE_URL+"uploads/"+chatHead['receiver.profilePic']} data-src="/assets/images/user.png" onError={constants.defaultImage} className="rounded-circle user_img_msg" alt="img" />
            </div>
            <div className="msg_cotainer"> {chatItem.message}
                <span className="msg_time">{constants.getTimeAgo(chatItem.createdAt)}</span>
            </div>
        </div>
    );
}
export default memo(ReceiverMessage);