import React, { useContext, useEffect, useRef, memo, useState } from 'react';
import { AuthContext } from '../helper/AuthContext';
import constants from '../helper/Constants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import SenderMessage from './SenderMessage';
import ReceiverMessage from './ReceiverMessage';
function ChatMessages({ dataItems, chatHead }) {
    const chatBodyView = useRef(null);
    const { userId } = useContext(AuthContext);

    useEffect(() => {
        if (chatBodyView.current) {
            chatBodyView.current.scrollTop = chatBodyView.current.scrollHeight;
        }
    }, [dataItems]);

    return (
        <PerfectScrollbar className='card-body msg_card_body' containerRef={el => (chatBodyView.current = el)} option={{
            suppressScrollX: true,
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20,
        }}>
            {(dataItems.length > 0) ? dataItems.map((chatItem, index) => {
                return (chatItem.receiverId !== userId) ? (
                    <SenderMessage key={chatItem.id} chatItem={chatItem} />
                ) : (
                    <ReceiverMessage key={chatItem.id} chatItem={chatItem} chatHead={chatHead} />
                );
            }) : (
                <div className="chat-box-single-line">
                    <abbr className="timestamp">Say hi to start chat</abbr>
                </div>
            )}
        </PerfectScrollbar>
    );
}

export default memo(ChatMessages);