import React, { useEffect, useState, useRef } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import constants from '../helper/Constants';
import BreadCrumb from './BreadCrumb';
function FetchData(props) {
    const [dataItems, setDataItems] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const initialPageLoaded = useRef(false);
    const [search, setSearch] = useState('');

    const searchHandler = () => {
        if (search !== '') {
            setPage(1);
            fetchDbData(true);
        }
    }

    const updatePage = () => {
        if (initialPageLoaded.current) {
            initialPageLoaded.current = false;
            setTimeout(() => {
                setPage(page + 1);
            }, 3000)
        }
    }
    let queryData = {}
    if (props.data !== undefined) {
        queryData = props.data;
    }

    const fetchDbData = (reset = false) => {
        constants.serverGet(props.url, { page: page, ...{ ...queryData, s: search } }).then((res) => {
            if (res.success === '1') {
                var newData = [...dataItems, ...res.data];

                if (reset)
                    newData = res.data;

                setDataItems(newData);
                initialPageLoaded.current = true;
                if (newData.length >= res.count) {
                    setHasMore(false);
                }
            }
        });
    }

    useEffect(() => {
        fetchDbData();
    }, [page]);

    useEffect(() => {
        if (props.filterData.process) {
            if (props.filterData.action == 'remove') {
                setDataItems((oldData) => oldData.filter((i, ind) => (i.id !== props.filterData.data.id)));
            }
            else if (props.filterData.action == 'unique-change') {
                setDataItems(dataItems.map((i, ind) => {
                    if (i.id === props.filterData.data.id)
                        return props.filterData.data;
                    else
                        return { ...i, ...props.filterData.sameData };
                }));
            }
            else {
                setDataItems(dataItems.map((i, ind) => {
                    if (i.id === props.filterData.data.id)
                        return props.filterData.data;
                    else
                        return i;
                }));
            }
            props.setFilterData({ process: false, index: null, data: null, sameData: null });
        }
    }, [props.filterData])

    return (
        <>
            <BreadCrumb title={props.title} />

            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="align-items-center d-flex justify-content-between w-100">
                                <h3 className="card-title">{props.subTitle}</h3>
                                {props.search !== undefined && props.search !== null ? (
                                    <div className="input-group my-3" style={{ width: '100%', maxWidth: 250 }}>
                                        <input placeholder={props.search} name={'s'} value={search} className='form-control' onChange={(event) => setSearch(event.target.value)} />
                                        <div className="input-group-append">
                                            <button className="input-group-text" onClick={searchHandler}><i className="fa fa-search"></i></button>
                                        </div>
                                    </div>
                                ) : ''}
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="grid-margin">
                                <div className="table-responsive">
                                    <InfiniteScroll
                                        dataLength={dataItems.length}
                                        next={updatePage}
                                        hasMore={hasMore}
                                        loader={
                                            <div className={'text-center m-5'}><i className='btn-loading btn-lg'></i></div>
                                        }
                                        endMessage={(dataItems.length >= 1) ? (<div className={'text-center m-5'}>No More Record!</div>) : ''}
                                    >
                                        <table className="table card-table border table-vcenter text-nowrap align-items-center text-center">
                                            <thead className='thead-light'>
                                                <tr>
                                                    {props.tableHeading.map((i, index) => {
                                                        return (<th key={index}>{i}</th>);
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (dataItems.length === 0) ? (
                                                        <>
                                                            <tr>
                                                                <td colSpan={props.tableHeading.length} className='text-center'>No Record Found!</td>
                                                            </tr>
                                                        </>
                                                    ) : dataItems.map((i, index) => props.getTable(i, index))
                                                }
                                            </tbody>
                                        </table>
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FetchData;