import constants from "./Constants";
import { SocketContext } from "./SocketContext";

class Otc {
    #forexCurrencyId = 0;
    #forexCurrencyName = '';
    #currentPrice = 0;
    #buyTotal = 0;
    #sellTotal = 0;
    #otcTimeInterval = null;
    #buyLowestPrice = Infinity;
    #sellHighestPrice = 0;
    #buySellSign = [true, false];
    #setCurrencyData = () => { };
    #setDataItems = () => { };
    #emitMessage = () => { };
    #socket = null;
    #lastForexCurrencyId = null
    setInitials(currencyId, currencyName, price, setCurrencyData, setDataItems, emitMessage,socket) {
        this.#currentPrice = price;
        this.#forexCurrencyName = currencyName;
        this.#forexCurrencyId = currencyId;
        this.#setCurrencyData = setCurrencyData;
        this.#setDataItems = setDataItems;
        this.#emitMessage = emitMessage;
        this.#socket = socket;
    }

    resetBuySell() {
        this.#buyTotal = 0;
        this.#buyLowestPrice = Infinity;
        this.#sellTotal = 0;
        this.#sellHighestPrice = 0;
    }

    setBuy(amount) {
        this.#buyTotal += parseFloat(amount);
    }

    setBuyLowest(currentPrice) {
        currentPrice = parseFloat(currentPrice);
        this.#buyLowestPrice = (this.#buyLowestPrice > currentPrice) ? currentPrice : this.#buyLowestPrice;
    }

    setSell(amount) {
        this.#sellTotal += parseFloat(amount);
    }

    setSellHighest(currentPrice) {
        currentPrice = parseFloat(currentPrice);
        this.#sellHighestPrice = (this.#sellHighestPrice < currentPrice) ? currentPrice : this.#sellHighestPrice;
    }

    #fixPrice = () => {
        this.#currentPrice = parseFloat(parseFloat(this.#currentPrice).toFixed(6));
    }

    #randomNumber() {
        return Math.random() * 0.01201201;
    }

    #randomSign() {
        return this.#buySellSign[Math.round(Math.random())];
    }

    #setRandom() {
        this.#currentPrice += this.#randomNumber() * (this.#randomSign() ? 1 : -1);
        if (this.#currentPrice < 0) {
            this.#currentPrice = this.#currentPrice - (this.#currentPrice * 2);
        }
        else if (this.#currentPrice > 100) {
            this.#currentPrice = this.#randomNumber() * 50;
        }
    }

    // #setUp() {
    //     // console.log('Up')
    //     this.#currentPrice = this.#sellHighestPrice + parseFloat(Math.random().toFixed(6));
    // }

    // #setDown = () => {
    //     // console.log('Down')
    //     this.#currentPrice = this.#buyLowestPrice - parseFloat(Math.random().toFixed(6));
    // }

    // #setUp() {
    //     console.log('Up')
    //     if (this.#buyLowestPrice < Infinity) {
    //         this.#currentPrice = ((this.#sellHighestPrice + this.#buyLowestPrice) / 2) + Math.random();
    //         console.log(this.#currentPrice);
    //     }
    //     else {
    //         this.#currentPrice = this.#sellHighestPrice + this.#randomNumber();
    //     }
    // }

    // #setDown = () => {
    //     // console.log('Down')
    //     if (this.#sellHighestPrice > 0) {
    //         this.#currentPrice = ((this.#sellHighestPrice + this.#buyLowestPrice) / 2) - Math.random();
    //     }
    //     else {
    //         this.#currentPrice = this.#buyLowestPrice - this.#randomNumber();
    //     }
    // }

    #setDown = () => {
        console.log('Down');
        // If sellHighestPrice is greater than 0, we calculate a price in the lower half of the range
        if (this.#sellHighestPrice > 0 && this.#buyLowestPrice < Infinity) {
            const averagePrice = (this.#sellHighestPrice + this.#buyLowestPrice) / 2;
            this.#currentPrice = averagePrice - Math.random();
        } else if (this.#buyLowestPrice < Infinity) {
            // If sellHighestPrice is not greater than 0 but this.#buyLowestPrice is set, decrement by a random value
            this.#currentPrice = this.#buyLowestPrice - this.#randomNumber();
        } else {
            // Default case: decrement the current price by a random value
            this.#currentPrice -= this.#randomNumber();
        }
    
        // Ensure the current price does not go below zero
        if (this.#currentPrice < 0) {
            this.#currentPrice = 0;
        }
    
       
    }
   #setUp=()=> {
            console.log('Up');
            // Ensure there's a small random increment
            const smallIncrement = this.#randomNumber()  // Example: up to 0.1% increase

            if (this.#buyLowestPrice < Infinity && this.#sellHighestPrice > 0) {
                this.#currentPrice = ((this.#sellHighestPrice + this.#buyLowestPrice) / 2) + smallIncrement;
            } else if (this.#sellHighestPrice > 0) {
                this.#currentPrice = this.#sellHighestPrice + smallIncrement;
            } else {
                this.#currentPrice += smallIncrement;
            }
        }

    getTime() {
        const d = new Date();
        return d.getTime() - d.getMilliseconds()
    }

    startOtc(data) {
        this.#otcTimeInterval = setInterval(() => {
            this.subscribeOtcData(data[0]);
            // });
           
        }, 1000);
    }

 subscribeOtcData(element) {
  console.log("this.#socket",this.#socket)
        if (this.#socket) {
            if(this.#lastForexCurrencyId && this.#lastForexCurrencyId === element.forexCurrencyId){
                this.#socket.off('currentTradingRate', this.handleForexPreviousRate);

            }else {
                this.#lastForexCurrencyId = element?.forexCurrencyId
            }
            this.#socket.emit('getCurrentTradingRate', { forexCurrencyId:element?.forexCurrencyId, isDemo:"1" });
             // Define the event handler function so it can be referenced for both `on` and `off`
        this.handleForexPreviousRate = (data) => {

            //<><<><><><><>*******************<><><><><><><> business logic fior cureent value 
            const buyOrSell = (this.#buyTotal >= this.#sellTotal) ? 'buy' : 'sell';
            if(data?.length){
                    let summary = data.reduce((acc, element) => {
                        let key = (element?.buySell == 0) ? 'buy' : 'sell';
                        if (acc[key]) {
                            acc[key] += element.investmentAmount;
                        } else {
                            acc[key] = element.investmentAmount;
                        }
                        return acc;
                    }, {});

                    if(!summary?.sell || !summary?.buy){
                        // Find the center index
                        let centerIndex = Math.floor(data.length / 2);
                        this.#currentPrice = data[centerIndex>0?centerIndex-1:centerIndex]?.currentPrice
                        // Split the array into left and right parts
                        let leftSide = data.slice(0, centerIndex);
                        let rightSide = data.slice(centerIndex);
                        let leftSum = leftSide.reduce((sum, element) => sum + element.investmentAmount, 0)
                        let rightSum = rightSide.reduce((sum, element) => sum + element.investmentAmount, 0);
                        
                        if(leftSum >  rightSum){
                            this.#setUp();
                        } else if(rightSum > leftSum){
                            this.#setDown();
                        } else{
                            this.#setRandom();
                        }
                    } else if (summary.buy > summary.sell) {
                        this.#setDown();
                    } else if (summary.sell > summary.buy) {
                        this.#setUp();
                    } else {
                        this.#setRandom();
                    }
            } else if (this.#buyLowestPrice > 0 && this.#buyLowestPrice <= this.#currentPrice && buyOrSell === 'buy') {
                this.#setDown();
            }
            else if (this.#sellHighestPrice > 0 && this.#sellHighestPrice >= this.#currentPrice && buyOrSell === 'sell') {
                this.#setUp();
            }
            else {
                console.log("random")
                this.#setRandom();
            }
            this.#fixPrice();
            var saveTime = this.getTime();
            this.#setCurrencyData({
                action: 'FOREX_UPDATE_RATE', data: {
                    p: this.#forexCurrencyName,
                    id: this.#forexCurrencyId,
                    t: saveTime,
                    a: this.#currentPrice,
                }, emitMessage: this.#emitMessage
            });
            this.#setDataItems({
                action: 'FOREX_UPDATE_RATE', data: {
                    p: this.#forexCurrencyName,
                    id: this.#forexCurrencyId,
                    t: saveTime,
                    a: this.#currentPrice,
                }, emitMessage: this.#emitMessage
            });
         //<><<><><><><>*******************<><><><><><><>


        };

        // Attach the event handler
        this.#socket.on('currentTradingRate', this.handleForexPreviousRate);
        } else {
            this.#socket = constants.socketIo()
            console.error("Socket is not defined for Otc instance.");
            return
        }
    }
    endOtc() {
        clearInterval(this.#otcTimeInterval);
    }
}

export default Otc;
