import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import FormField from '../../components/FormField';
import TextArea from '../../components/TextArea';
import { success, warning, error as toastrError } from 'toastr';
import constants from '../../helper/Constants';
import { useSearchParams } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';

function AddEditBotQuestion() {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialValues = {
        question: "",
        answer: "",
    };
    const detailsForm = useRef();
    const validationSchema = Yup.object().shape({
        question: Yup.string().required("Qestion is a required field"),
        answer: Yup.string().required("Answer is a required field"),
    });

    const onSubmit = (data, { setSubmitting, resetForm }) => {
        if (searchParams.get("editId") !== null) {
            data.id = searchParams.get("editId");
        }
        constants.serverPost('chat-bot-message/save', data).then((res) => {
            setSubmitting(false);
            if (res.success === '1') {
                success(res.message);
                if (searchParams.get("editId") === null) {
                    resetForm();
                }
            }
            else {
                warning(res.message);
            }
        }).catch((error) => {
            toastrError("The server is not responding, please try again later.");
            setSubmitting(false);
        });
    };

    useEffect(() => {
        if (searchParams.get("editId") !== null) {
            constants.serverGet('chat-bot-message', { id: searchParams.get("editId") }).then((res) => {
                if (res.data !== null) {
                    detailsForm.current.setValues({
                        question: res.data.question,
                        answer: res.data.answer,
                    });
                }
            });
        }
    }, [])
    return (
        <>
            <BreadCrumb title={'Save Question'} />

            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8">
                    <div className="card">
                        <div className="card-body">
                            <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema} innerRef={detailsForm} >
                                {
                                    ({ isSubmitting }) => (
                                        <Form>
                                            <div className="mt-4">
                                                <FormField
                                                    label={"Question"}
                                                    name="question"
                                                    placeholder={"ex. How to withdraw?"}
                                                />
                                                <TextArea
                                                    label={"Answer"}
                                                    name="answer"
                                                />
                                                <div className="form-footer mb-5">
                                                    <Field type="submit" disabled={isSubmitting} className="btn btn-primary-light btn-block" value="Save" />
                                                </div>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddEditBotQuestion;