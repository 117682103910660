import RenderTradings from "./RenderTradings";

function RenderInvestment({ currencyState }) {
    const forexPairs = Object.keys(currencyState);
    return (
        <div className="card">
            <div className="card-header">
                <div className="align-items-center d-flex justify-content-between w-100">
                    <h3 className="card-title">Users List</h3>
                </div>
            </div>
            <div className="card-body">
                <div className="grid-margin">
                    <div className="table-responsive">
                        <div className="infinite-scroll-component__outerdiv">
                            <div className="infinite-scroll-component " style={{ height: "auto", overflow: "auto" }}>
                                <table className="table card-table border table-vcenter text-nowrap align-items-center text-center">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Pair</th>
                                            <th>Unix Time</th>
                                            <th>Price</th>
                                            <th>Tradings</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {forexPairs.map((pair, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{pair}</td>
                                                    <td>{currencyState[pair].unixTime}</td>
                                                    <td>{currencyState[pair].price}</td>
                                                    <td>{<RenderTradings tradings={currencyState[pair]?.tradings} livePrice={currencyState[pair].price} />}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RenderInvestment;