import React, { useState } from 'react';
import FetchData from '../../components/FetchData';
import constants from '../../helper/Constants';
function BlockUsers() {
    const [filterData, setFilterData] = useState({ process: false, index: null, data: null, action: 'change' });

    const getTable = (i, index) => {
        return (
            <tr key={index} className={(i.newDocuments) ? 'bg-indigo-dark' : ''}>
                <td>{i.id}</td>
                <td>{i.userName}</td>
                <td>{i.wallet}</td>
                <td>{i.depositCount}</td>
                <td>{i.depositAmount}</td>
                <td>{i.withdrawalCount}</td>
                <td>{i.withdrawalAmount}</td>
                {/* <td>{0}</td>
                <td>{0}</td> */}
                <td>{new Date(i.createdAt).toLocaleString()}</td>
                <td>
                    <button type='button' onClick={() => {
                        i.blockStatus = (i.blockStatus === '0') ? '1' : '0';
                        constants.changeStatus('user/change-user-status', i, `Do you want to ${(i.blockStatus === '0') ? 'block' : 'un-block'}?`, setFilterData);
                    }} className={`tag tag-${(i.blockStatus === '0') ? 'red' : 'green'} mr-1`}>{(i.blockStatus === '0') ? 'Block' : 'Un-Block'}</button>
                </td>
            </tr>
        )
    }

    return (
        <>
            <FetchData
                url='user/get-users'
                data={{ blockStatus: '1' }}
                title="Blocked Users"
                subTitle="Users List"
                filterData={filterData}
                setFilterData={setFilterData}
                getTable={getTable}
                tableHeading={['Trader Id', 'Username', 'Wallet', 'Deposit Count', 'Deposit Amount', 'Withdrawal Count', 'Widthdrawal Amount', /*'Turn Over', 'Rev.Share',*/, 'Created At', 'Action',]} />
        </>
    );
}

export default BlockUsers;