import React, { memo } from "react";

function NoChatHead() {
    return (
        <>
            <div className="card-body msg_card_body text-center" style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                flexWrap: "wrap",
                alignContent: "center",
                gap: '100px',
            }}>
                <div>
                    <img src="../assets/images/chat.png" style={{ maxWidth: '50%' }} alt="" />
                </div>
                <div>
                    <h1>Earn Tex</h1>
                </div>
            </div>
        </>
    );
}

export default memo(NoChatHead);