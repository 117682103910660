function CurrencyCard({ value, i, updateForexCurrency, setCurrencyData, setDataItems }) {
    return (
        <div className="col-lg-4 p-2">
            <div className="border w-100 h-100">
            <div className="d-flex justify-content-between">
                <h2>{value.currencyName}</h2>
                <div className="material-switch pull-right">
                    <input id={`currencyName-${i}`} value={value.id} onChange={(event) => {
                        value.status = (event.target.checked) ? '1' : '0';
                        updateForexCurrency(value)
                    }} checked={(value.status === '1') ? 'checked' : ''} type="checkbox" />
                    <label htmlFor={`currencyName-${i}`} className="label-primary"></label>
                </div>
            </div>
            <p>{value.livePrice}</p>
            <div className="input-group">
                <input type="text" className="form-control" value={value.profit} onChange={(e) => {
                    value.profit = e.target.value;
                    setCurrencyData({ action: 'FOREX_UPDATE', data: value });
                    setDataItems({ action: 'FOREX_UPDATE', data: value });
                }} placeholder="%" onBlur={(event) => {
                    value.profit = event.target.value;
                    updateForexCurrency(value)
                }} />
                <span className="input-group-append">
                    <i className="btn btn-primary-light" type="button">%</i>
                </span>
            </div>
            </div>
        </div>
    );
}

export default CurrencyCard;