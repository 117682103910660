import { memo, useContext } from "react";
import { AuthContext } from "../helper/AuthContext";
import constants from "../helper/Constants";
import { NavLink } from 'react-router-dom';
function Header({ basePath }) {
    const { userName, profilePic, name } = useContext(AuthContext);
    return (
        <div className="header header-fixed ">
            <div className="container-fluid">
                <div className="d-flex">
                    <a id="horizontal-navtoggle" className="animated-arrow hor-toggle"><span></span></a>
                    <NavLink className="header-brand d-md-none" to={basePath + "/"}>
                        <img src="/logo.png" className="header-brand-img desktop-logo"
                            alt="Earn Tex Logo" />
                        <img src="/logo.png"
                            className="header-brand-img mobile-view-logo" alt="Earn Tex Logo" />
                    </NavLink>
                    <a aria-label="Hide Sidebar" className="app-sidebar__toggle d-flex flex-column justify-content-around" data-toggle="sidebar"
                        href="#"></a>
                    <div className="d-flex order-lg-2 ml-auto header-right-icons header-search-icon">
                        {/* <a href="#" data-toggle="search" className="nav-link nav-link-lg d-md-none navsearch"><i
                            className="ion ion-search"></i></a>
                        <div className="">
                            <form className="form-inline">
                                <div className="search-element">
                                    <input type="search" className="form-control header-search"
                                        placeholder="Search…" aria-label="Search" tabIndex="1" />
                                    <button className="btn btn-primary-color" type="submit"><i
                                        className="ion ion-search"></i></button>
                                </div>
                            </form>
                        </div>
                        <div className="dropdown d-md-flex">
                            <a className="nav-link icon full-screen-link nav-link-bg">
                                <i className="fe fe-maximize-2" id="fullscreen-button"></i>
                            </a>
                        </div> */}
                        {/* <div className="dropdown d-md-flex notifications">
                            <a className="nav-link icon" data-toggle="dropdown">
                                <i className="fe fe-bell"></i>
                                <span className="nav-unread bg-warning-1 pulse"></span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right  dropdown-menu-arrow">
                                <div className="dropdown-item p-4 bg-image-2 text-center text-white">
                                    <h4 className="mb-1">3 New </h4>
                                    <span
                                        className="text-white-transparent user-semi-title">Notifications</span>
                                </div>
                                <a href="#" className="dropdown-item mt-2 d-flex pb-3">
                                    <div className="notifyimg bg-success">
                                        <i className="fa fa-thumbs-o-up"></i>
                                    </div>
                                    <div>
                                        <strong>Someone likes our posts.</strong>
                                        <div className="small">3 hours ago</div>
                                    </div>
                                </a>
                                <div className="dropdown-divider"></div>
                                <a href="#" className="dropdown-item text-center">View all Notification</a>
                            </div>
                        </div>
                        <div className="dropdown d-md-flex message">
                            <a className="nav-link icon text-center" data-toggle="dropdown">
                                <i className="fe fe-mail floating"></i>
                                <span className="nav-unread bg-warning-1 pulse"></span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                <div className="dropdown-item p-4 bg-image-3 text-center text-white">
                                    <h4 className="mb-1">4 New </h4>
                                    <span className="text-white-transparent user-semi-title">Messages</span>
                                </div>
                                <a href="#" className="dropdown-item d-flex pb-3">
                                    <div className="avatar avatar-md brround mr-3 d-block cover-image"
                                        data-image-src="../assets/images/user.png"
                                        style={
                                            {
                                                background: "url('../assets/images/user.png') center center"
                                            }
                                        }>
                                        <span className="avatar-status bg-red"></span>
                                    </div>
                                    <div>
                                        <strong>Anthony</strong>
                                        <p className="mb-0 fs-13 ">New product Launching</p>
                                        <div className="small">5 hour ago</div>
                                    </div>
                                </a>
                                <div className="dropdown-divider"></div>
                                <a href="#" className="dropdown-item text-center">See all Messages</a>
                            </div>
                        </div> */}
                        <div className="dropdown text-center selector profile-1">
                            <a href="#" data-toggle="dropdown" className="nav-link leading-none d-flex">
                                <span><img src={process.env.REACT_APP_SERVER_BASE_URL+"uploads/"+ profilePic || 'default-image'} onError={constants.defaultImage} data-src="/assets/images/user.png" alt="profile-user"
                                    className="avatar avatar-sm brround cover-image mb-1 ml-0" /></span>
                                <span className=" ml-3 d-none d-lg-block ">
                                    <span className="text-white ">{userName}</span>
                                </span>
                            </a>
                            {/* <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                <div className="text-center bg-image p-4 text-white">
                                    <a href="#"
                                        className="dropdown-item text-white text-center font-weight-sembold user"
                                        data-toggle="dropdown">{(name !== '') ? name : userName}</a>
                                </div>
                                <a className="dropdown-item" href="#">
                                    <i className="dropdown-icon mdi mdi-account-outline"></i> Profile </a>
                                <a className="dropdown-item" href="#">
                                    <i className="dropdown-icon  mdi mdi-settings"></i> Settings </a>
                                <a className="dropdown-item" href="#">
                                    <span className="float-right"><span
                                        className="badge badge-danger">6</span></span>
                                    <i className="dropdown-icon mdi  mdi-message-outline"></i> Inbox </a>
                                <a className="dropdown-item" href="#">
                                    <i className="dropdown-icon mdi mdi-comment-check-outline"></i> Message
                                </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#">
                                    <i className="dropdown-icon mdi mdi-compass-outline"></i> Need help? </a>
                                <a className="dropdown-item" href="#">
                                    <i className="dropdown-icon mdi  mdi-logout-variant"></i> Sign out </a>
                            </div> */}
                        </div>

                    </div>
                    <a href="#" className="header-toggler d-lg-none ml-lg-0" data-toggle="collapse"
                        data-target="#headerMenuCollapse">
                        <span className="header-toggler-icon"></span>
                    </a>
                </div>
            </div>
        </div>
    )
}
export default memo(Header);