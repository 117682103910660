import React, { useState } from 'react';
import constants from '../../helper/Constants';
import FetchData from '../../components/FetchData';
import LargeImageModal from '../../components/LargeImageModal';
import { Link } from 'react-router-dom';
function PendingDepositStatement({ userId }) {
    const [filterData, setFilterData] = useState({ process: false, index: null, data: null, action: 'change' });
    const getTable = (i, index) => {
        return (
            <tr key={index}>
                <td><Link to={'/user-profile/' + i.userId} className='r-link'>{(i?.user?.userName||"") + ((i?.user?.name) ? ' (' + i.user.name + ')' : '')}</Link></td>
                <td>{i.txnId}</td>
                <td>{constants.depositTypes[i.depositType]}</td>
                <td>{i.amount}</td>
                <td>{i.benefit || '--'}</td>
                <td>{i.benefitType || '--'}</td>
                <td>{(i.upi !== undefined && i.upi !== null) ? i.upi.upi : ''}</td>
                <td>
                    <img src={process.env.REACT_APP_SERVER_BASE_URL+"uploads/"+  i.slip} alt="" onError={constants.defaultImage} style={{ cursor: 'pointer' }} height={70} className="large-image" />
                </td>
                <td>{new Date(i.createdAt).toLocaleString()}</td>
                <td>
                    {(i.status === '0') ? (
                        <>
                            <button type='button' onClick={() => {
                                i.status = '1';
                                constants.changeStatus('deposit/verify-deposit', i, "Once accepted, you will not be able to recover!", setFilterData, 'change');
                            }} className={`tag tag-green mr-1`}>Accept</button>
                            <button type='button' onClick={() => {
                                i.status = '2';
                                constants.changeStatus('deposit/verify-deposit', i, "Once rejected, you will not be able to recover!", setFilterData, 'change');
                            }} className={`tag tag-red`}>Reject</button>
                        </>
                    ) : (
                        <span className={`tag tag-${(i.status === '1') ? 'green' : 'red'}`}>{(i.status === '1') ? 'Accepted' : 'Rejected'}</span>
                    )}
                </td>
            </tr>
        );
    }

    return (
        <>
            <FetchData
                url='deposit/get-deposits'
                data={(userId !== undefined) ? { userId, status: '0' } : { status: '0' }}
                title="Deposit Statement"
                subTitle="Deposit Fund List"
                filterData={filterData}
                setFilterData={setFilterData}
                getTable={getTable}
                tableHeading={['User', 'Transaction ID', 'Payment Type', 'Amount', 'Bonus', 'Bonus Type', 'UPI ID', 'Transaction Slip', 'Transaction Time', 'Status']} />
            <LargeImageModal />
        </>
    );
}

export default PendingDepositStatement;