import { memo } from "react";
import { Field, ErrorMessage } from 'formik';
function FormField({ type, name, placeholder, label }) {
    return (
        <div className="form-group">
            <label>{label}</label>
            <Field type={type ?? "text"} name={name} placeholder={placeholder ?? ""} className="form-control" />
            <ErrorMessage name={name} component='small' className='error' />
        </div>
    );
}
export default memo(FormField);