import React, { useState } from 'react';
import FetchData from '../../components/FetchData';
import LargeImageModal from '../../components/LargeImageModal';
import Constants from '../../helper/Constants';
import { error as toastrError } from 'toastr';
import { Link } from 'react-router-dom';
function UpiList({ userId }) {
    const [filterData, setFilterData] = useState({ process: false, index: null, data: null, action: 'change', sameData: null });

    const changeHandler = (data, index, status) => {
        Constants.serverPost('upi/change-status', {
            upiId: data.id,
            status: status,
        }).then((res) => {
            if (res.success === '1') {
                setFilterData({ process: true, index, data: { ...data, status: status } });
            }
            else {
                toastrError("The server is not responding, please try again later." || res.message);
            }
        }).catch((error) => {
            toastrError('Something went wrong, please try again later.' || error);
        });
    }
    const getTable = (i, index) => {
        return (<tr key={index}>
            <td>{i.appName}</td>
            <td><img src={process.env.REACT_APP_SERVER_BASE_URL+"uploads/"+  i.appLogo || ''} alt="QR Code" onError={Constants.defaultImage} height={70} className="large-image" /></td>
            <td>{i.upi}</td>
            <td><img src={process.env.REACT_APP_SERVER_BASE_URL+"uploads/"+ i.qrCode || ''} alt="QR Code" onError={Constants.defaultImage} height={70} className="large-image" /></td>
            <td>{new Date(i.createdAt).toLocaleString()}</td>
            <td>
                <div className="d-flex justify-content-around">
                    <div className="material-switch pull-right js-td-switch">
                        <input id={`upiId-${index}`} value={i.id} checked={(i.status === '1') ? 'checked' : ''} type="checkbox" onChange={(event) => changeHandler(i, index, (i.status === '1') ? '0' : '1')} />
                        <label htmlFor={`upiId-${index}`} className="label-primary"></label>
                    </div>
                </div>
            </td>
            <td> <Link to={'/add-edit-upi/' + i.id} className="btn btn-radius btn-info-light py-0 mx-1">Edit</Link></td>
        </tr>)
    }

    return (
        <>
            <FetchData
                url='upi'
                title="Upi IDs"
                subTitle="Upi IDs List"
                filterData={filterData}
                setFilterData={setFilterData}
                getTable={getTable}
                tableHeading={['App Name', 'App Logo', 'Upi ID', 'QR Code', 'Created At', 'Status', 'Action']} />

            <LargeImageModal />
        </>
    );
}

export default UpiList;