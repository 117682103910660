import Constants from "../helper/Constants";

function DocumentObject({ data, clickHandler }) {
    console.log("sss", data.src)
    return (
        <>
            {(data.src !== '') ? (
                <div className="thumbnail">
                    <div className="thumbnail-img-wrapper">
                        <img src={process.env.REACT_APP_SERVER_BASE_URL+"uploads/"+ data.src} alt={data.title} className="thumbimg large-image" onError={Constants.defaultImage} />
                    </div>
                    <div className="caption"> <h4><strong>{data.title}</strong></h4>
                        {(data.status === '0') ? (
                            <>
                                <button className="btn btn-success-light btn-sm mr-2" onClick={() => {
                                    clickHandler(data.name, '1');
                                }}>Approve</button>
                                <button className="btn btn-warning-light btn-sm mr-2" onClick={() => {
                                    clickHandler(data.name, '2');
                                }}>Reject</button>
                            </>
                        ) : ((data.status == '1') ? (
                            <i className="btn btn-success-light btn-sm mr-2">Approved</i>
                        ) : (
                            <i className="btn btn-warning-light btn-sm mr-2">Rejected</i>
                        ))}
                    </div>
                </div>
            ) : (<></>)}
        </>
    );
}

export default DocumentObject;