import React, { useEffect, useState } from 'react';
import Widget from '../../components/Widget';
// import { AuthContext } from '../../helper/AuthContext';
import constants from '../../helper/Constants';
import BreadCrumb from '../../components/BreadCrumb';

function Dashboard() {
    // const { createdAt } = useContext(AuthContext);
    const [widgetList, setWidgetList] = useState([]);

    const onClickHandler = async () => {
        const res = await constants.serverGet('dashboard/restart');
        if(res.success === '1') {
            constants.reload();
        }
    }

    useEffect(() => {
        constants.serverGet('dashboard').then((res) => {
            if (res.success === '1') {
                setWidgetList(res.data);
            }
        });
    }, []);

    return (
        <>
            <BreadCrumb title={'Dashboard'} />

            <div className="row">
                {(widgetList.length > 0) ? widgetList.map((widget, index) => {
                    return (
                        <Widget
                            key={index}
                            title={widget.title}
                            value={widget.value}
                            textClass={widget.textClass}
                            subTitle={widget.subTitle}
                            url={widget.url}
                        />
                    );
                }) : ""}
            </div>
            <div className="row my-5">
                <div className="col text-center">
                    <button className='btn btn-danger' onClick={onClickHandler}>Restart</button>
                </div>
            </div>
        </>
    );
}

export default Dashboard;