import { memo } from "react";
import { Field, ErrorMessage } from 'formik';
function FileField({ name, label, setFieldValue }) {
    const onChange = (event) => {
        setFieldValue(name + 'File', event.currentTarget.files[0]);
        setFieldValue(name, event.currentTarget.value);
    }
    return (
        <div className="form-group">
            <label>{label}</label>
            <Field type={"file"} name={name} className="form-control" onChange={onChange} />
            <ErrorMessage name={name} component='small' className='error' />
        </div>
    );
}
export default memo(FileField);