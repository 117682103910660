import { memo } from 'react';
function LargeImageModal() {
    return (
        <>
            <div id="transaction-slip-modal" className="modal fade" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content ">
                        <div className="modal-body pd-20">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <img src="" id="transaction-slip-modal-image" style={
                                {
                                    width: "100%"
                                }
                            } alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(LargeImageModal);