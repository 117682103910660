import React, { useReducer, useEffect, useState, useContext, useDeferredValue } from 'react';
import * as Yup from 'yup';
import constants from '../../helper/Constants';
import Otc from '../../helper/Otc';
import { error, success } from 'toastr';
import BreadCrumb from '../../components/BreadCrumb';
import CurrencyCard from '../../components/CurrencyCard';
import { SocketContext } from '../../helper/SocketContext';
import AddCurrencyModal from '../../components/AddCurrencyModal';
// import axios from 'axios';
import RenderInvestment from './RenderInvenstment';
const currencyOtcObjects = [];
const setCurrencyDataReducer = (state, action) => {
    switch (action.action) {
        case 'FOREX_INIT': {
            var setState = {};
            action.data.map((d) => {
                setState[d.currencyName] = {
                    unixTime: (d.forexCurrencyData !== undefined && d.forexCurrencyData !== null && (d.forexCurrencyData?.length) > 0) ? (d?.forexCurrencyData[0]?.unixTime) : 0,
                    price: (d?.forexCurrencyData !== undefined && d.forexCurrencyData !== null && (d.forexCurrencyData?.length) > 0) ? (d?.forexCurrencyData[0]?.price) : 0,
                    id: d.id,
                    tradings: {},
                }
                return 0;
            });
            return setState;
        }
        case 'FOREX_UPDATE_RATE': {
            const currencyName = action.data.p;
            if (state[currencyName].unixTime !== action.data.t) {
                action.emitMessage('saveForexShareOCHL', {
                    forexCurrencyId: state[currencyName]?.id,
                    price: state[currencyName]?.price,
                    unixTime: state[currencyName]?.unixTime,
                    currencyName: currencyName,
                });
            }
            state[currencyName].unixTime = action.data.t;
            state[currencyName].price = action.data.a;
            action.emitMessage('forexShareOCHL', {
                forexCurrencyId: state[currencyName].id,
                price: state[currencyName]?.price,
                unixTime: state[currencyName]?.unixTime,
                currencyName: currencyName,
            });
            // axios.post(process.env.REACT_APP_DATA_SERVER_BASE_URL + 'candlesticks/save', {
            //     currencyId: state[currencyName].id,
            //     price: state[currencyName].price,
            //     timestamp: state[currencyName].unixTime,
            // });
            return state;
        }
        case 'FOREX_UPDATE': {
            for (var currencyName in state) {
                if (Number(state[currencyName].id) === Number(action.data.id)) {
                    state[currencyName] = {
                        unixTime: (action.data.forexCurrencyData !== undefined && action.data.forexCurrencyData !== null && action.data.forexCurrencyData.length > 0) ? (action.data.forexCurrencyData[0]?.unixTime) : 0,
                        price: (action.data.forexCurrencyData !== undefined && action.data.forexCurrencyData !== null && action.data.forexCurrencyData.length > 0) ? (action.data.forexCurrencyData[0]?.price) : 0,
                        id: action.data.id,
                        tradings: (state[currencyName].tradings === undefined || state[currencyName].tradings === null) ? {} : state[currencyName].tradings,
                    };
                }
            }
            return state;
        }
        case 'FOREX_INSERT': {
            state[action.data.currencyName] = {
                unixTime: (action.data.forexCurrencyData !== undefined && action.data.forexCurrencyData !== null && action.data.forexCurrencyData.length > 0) ? action.data.forexCurrencyData[0].unixTime : 0,
                price: (action.data.forexCurrencyData !== undefined && action.data.forexCurrencyData !== null && action.data.forexCurrencyData.length > 0) ? action.data.forexCurrencyData[0].price : 0,
                id: action.data.id,
                tradings: {},
            };
            return state;
        }
        case 'OHCL_ADD': {
            const setState = { ...state };
            for (const key in setState) {
                if (Number(setState[key].id) === Number(action.data.forexCurrencyId)) {
                    if (setState[key]['tradings'][action.data.resultTime] === undefined || setState[key]['tradings'][action.data.resultTime] === null) {
                        setState[key]['tradings'][action.data.resultTime] = [action.data];
                    }
                    else {
                        let alreadyExists = false;
                        setState[key]['tradings'][action.data.resultTime].forEach((trade) => {
                            if (Number(trade.id) === Number(action.data.id)) {
                                alreadyExists = true;
                            }
                        });

                        if (alreadyExists === false)
                            setState[key]['tradings'][action.data.resultTime] = [...setState[key]['tradings'][action.data.resultTime], action.data];
                    }
                }
            }
            // console.log('setState::', setState);
            return setState;
        }
        case 'OHCL_REMOVE': {
            const setState = { ...state };
            for (const key in setState) {
                for (const resultTime in setState[key]['tradings']) {
                    if (resultTime < action.resultTime) {
                        delete setState[key]['tradings'][resultTime];
                    }
                }

            }
            return setState;
        }
        case 'OHCL_FILTER': {
            const setState = { ...state };
            for (const key in setState) {
                for (const resultTime in setState[key]['tradings']) {
                    const oldList = [...setState[key]['tradings'][resultTime]];
                    setState[key]['tradings'][resultTime] = oldList.filter((item, index) => {
                        return index === oldList.findIndex(obj => obj.id === item.id);
                    });
                }
            }

            return setState;
        }
        case 'OHCL_UPDATE_RATE': {
            const setState = { ...state };
            for (const key in setState) {
                if (Number(setState[key].id) === Number(action.data.forexCurrencyId)) {
                    currencyOtcObjects[key].resetBuySell();
                    if (setState[key]['tradings'][action.data.resultTime] !== undefined && setState[key]['tradings'][action.data.resultTime] !== null) {
                        if (setState[key]['tradings'][action.data.resultTime].length > 0) {
                            setState[key]['tradings'][action.data.resultTime].map((value) => {
                                if (value.buySell === '0') {
                                    currencyOtcObjects[key].setBuy(value.investmentAmount);
                                    currencyOtcObjects[key].setBuyLowest(value.currentPrice);
                                }
                                else {
                                    currencyOtcObjects[key].setSell(value.investmentAmount);
                                    currencyOtcObjects[key].setSellHighest(value.currentPrice);
                                }
                                return 0;
                            });
                        }
                    }
                }
            }
            return setState;
        }
        default: {
            return state;
        }
    }
}
const initialCurrencyData = { items: [] };

const setItemReducer = (state, action) => {
    switch (action.action) {
        case 'FOREX_INIT': {
            return action.data;
        }
        case 'FOREX_UPDATE_RATE': {
            const currencyName = action.data.p;
            return state.map((stateItem, index) => {
                if (stateItem.currencyName === currencyName) {
                    return { ...stateItem, livePrice: action.data.a };
                }
                else {
                    return stateItem;
                }
            });
        }
        case 'FOREX_UPDATE': {
            return state.map((stateItem, index) => {
                if (Number(stateItem.id) === Number(action.data.id)) {
                    return action.data;
                }
                else {
                    return stateItem;
                }
            });
        }
        case 'FOREX_INSERT': {
            if (state.items)
                return [...state.items, action.data];
            else
                return [action.data];
        }
        default: {
            return state;
        }
    }
}
const initialItems = [];
function ForexCurrencyOtcData({ emitMessage }) {
    const [socketConnection, setSocketConnection] = useState('Loading...');
    const [currencyState, setCurrencyData] = useReducer(setCurrencyDataReducer, initialCurrencyData);
    const [dataItems, setDataItems] = useReducer(setItemReducer, initialItems);
    const { socket } = useContext(SocketContext);
    const [resultTime, setResultTime] = useState(Date.now());
    const diffResultTime = useDeferredValue(resultTime);
    
    const updateForexCurrency = (data) => {
         console.log("data",data)
        removeInstance(data.currencyName);
        constants.serverPost('forex-currency/update', data).then((res) => {
            if (res.success === '1') {
                setCurrencyData({ action: 'FOREX_UPDATE', data: res.data });
                setDataItems({ action: 'FOREX_UPDATE', data: res.data });
                if (res.data.status === '1') {
                    createInstance(res.data);
                }
                success(res.message);
            }
            else {
                error(res.message || "Something went wrong, please try again later.");
            }
        })
    }

    const createInstance = (data) => {
        if (currencyOtcObjects[data.currencyName] === undefined || currencyOtcObjects[data.currencyName] === null) {
            console.log("startOtc start......")
            currencyOtcObjects[data.currencyName] = new Otc();
            currencyOtcObjects[data.currencyName].setInitials(data.id, data.currencyName, (data.forexCurrencyData !== undefined && data.forexCurrencyData !== null && data.forexCurrencyData.length > 0) ? data.forexCurrencyData[0].price : 0, setCurrencyData, setDataItems, emitMessage,socket);
            currencyOtcObjects[data.currencyName].startOtc(data.forexCurrencyData);
        }
    }

    const removeInstance = (currencyName) => {
        if (currencyOtcObjects[currencyName] !== undefined && currencyOtcObjects[currencyName] !== null) {
            currencyOtcObjects[currencyName].endOtc();
            currencyOtcObjects[currencyName] = null;
        }
    }

    function addTrading(data) {
        setCurrencyData({ action: 'OHCL_ADD', data });
        // console.log(resultTime, parseFloat(data.resultTime), resultTime === parseFloat(data.resultTime));
        if (resultTime === parseFloat(data.resultTime)) {
            // console.log('Updating...');
            setCurrencyData({ action: 'OHCL_UPDATE_RATE', data: { resultTime: diffResultTime, forexCurrencyId: data.forexCurrencyId } });
        }
    }
    function getPendingTrades() {
        // pending - trades
        constants.serverGet('trading/admin-pending-trades').then((res) => {
            if (res.success === '1') {
                res.data.forEach((data) => {
                    addTrading(data);
                });
            }
        });
    }
    useEffect(() => {
        constants.serverGet('forex-currency/get-currencies', { type: '1' }).then((data) => {
            if (data.success === '1') {
                setSocketConnection(false);
                setCurrencyData({ action: 'FOREX_INIT', data: data.data });
                setDataItems({ action: 'FOREX_INIT', data: data.data });
                data.data.map((currencyItem) => {
                    if (currencyItem.status === '1') {
                        createInstance(currencyItem);
                    }
                    return 0;
                });
            }
            else {
                setSocketConnection('No Record found!')
            }
        });

        constants.serverPost('otc-status/save', {
            status: '1',
        }).then((res) => {
            if (res.success === '1') {
                success(res.message || 'Record updated successfully.');
            }
            else {
                error(res.message || 'Something went wrong, please try again.');
            }
        });

        return () => {
            for (var otcCurrency in currencyOtcObjects) {
                removeInstance(otcCurrency);
            }
        };
    }, []);
    useEffect(() => {
        if (socket !== false) {
            socket.emit('message', {
                action: 'joinRoom',
                room: 'otcTradingInvestment',
            });
            socket.emit('message', {
                action: 'joinRoom',
                room: 'resultTime',
            });

            socket.on('nextResultTime', (data) => {
                // console.log('nextResultTime', data);
                setResultTime(data);
            });
            socket.on('otcTradingData', (data) => {
                // console.log('otcTradingData', data);
                addTrading(data);
            });
            socket.on('message', (data) => {
                if (data.success === '1') {
                    success(data.message);
                }
                else {
                    error(data.message);
                }
            });
            return () => {
                socket.off('nextResultTime');
                socket.off('otcTradingData');
                socket.off('message');
                socket.emit('message', {
                    action: 'leaveRoom',
                    room: 'otcTradingInvestment',
                })
            }
        }
    }, [socket]);

    useEffect(() => {
        setCurrencyData({ action: 'OHCL_REMOVE', resultTime: diffResultTime });
        setTimeout(() => {
            getPendingTrades();
        }, 10000);
    }, [diffResultTime]);

    const initialValues = {
        currencyName: "",
    }

    const onSubmit = (data, { resetForm }) => {
        data.type = '1';
        constants.serverPost('forex-currency/save', data).then((res) => {
            if (res.success === '1') {
                setCurrencyData({ action: 'FOREX_INSERT', data: res.data });
                setDataItems({ action: 'FOREX_INSERT', data: res.data });
                createInstance(res.data);
                success(res.message);
                resetForm();
            }
            else {
                error(res.message || "Something went wrong, please try again later.");
            }
        })
    }

    const validationSchema = Yup.object({
        currencyName: Yup.string().required("Forex currency name is required").min(5, 'Currency Name must be at least 5 characters'),
    });

    return (
        <>
            <BreadCrumb title={'Currency Price'} />

            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <h3 className="card-title">Live Prices</h3>
                            <button className="btn btn-outline-primary btn-sm" data-target="#add-forex-currency-modal" data-toggle="modal"><i className="fa fa-plus"></i> Add Currency</button>
                        </div>
                        <div className="card-body">
                            <div className="grid-margin">
                                <div className="row">
                                    <div className="col">
                                        <h1>Result Time:- {`${new Date(diffResultTime).getHours()}:${new Date(diffResultTime).getMinutes()}`}</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    {socketConnection ? (
                                        <div className='w-100 text-center'>
                                            <h2 className='mt-5 mb-5'>{socketConnection}</h2>
                                        </div>
                                    ) : ''}
                                    {dataItems.map((value, i) => {
                                        return (
                                            <CurrencyCard {...{ value, i, updateForexCurrency, setCurrencyData, setDataItems }} key={i} />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-12">
                    <RenderInvestment currencyState={currencyState} />
                </div>
            </div>

            <AddCurrencyModal initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} />
        </>
    );
}

export default ForexCurrencyOtcData;