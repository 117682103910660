import './App.css';
import UserBase from './pages/user/UserBase';
import AuthBase from './pages/auth/AuthBase';
import { Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import { AuthContext } from './helper/AuthContext';
import '../node_modules/toastr/build/toastr.css';
import Loader from './components/Loader';

function App() {
  const [authStatus, setAuthStatus] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [userId, setUserId] = useState(false);
  const [profilePic, setProfilePic] = useState("../assets/images/user.png");
  const [userName, setUserName] = useState("Loading...");
  const [name, setName] = useState("Loading...");
  const [createdAt, setCreatedAt] = useState(new Date().toLocaleDateString("en-US", {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }));

  return (
    <>
      <AuthContext.Provider value={{ authStatus, setAuthStatus, userName, setUserName, createdAt, setCreatedAt, userId, setUserId, profilePic, setProfilePic, name, setName, setShowLoader }}>
        <Routes>
          <Route path='/*' element={
            (authStatus) ? <UserBase basePath={''} /> : <AuthBase basePath={'/auth'} />
          } />
          <Route path='/auth/*' element={
            (authStatus) ? <UserBase basePath={''} /> : <AuthBase basePath={'/auth'} />
          } />
        </Routes>
        <Loader display={showLoader} />
      </AuthContext.Provider>
    </>
  );
}

export default App;
