import { memo } from "react";
import { Field, ErrorMessage } from 'formik';
function SelectField({ name, label, options }) {
    return (
        <div className="form-group">
            <label>{label}</label>
            <Field name={name} component='select' className="form-control">
                {options}
            </Field>
            <ErrorMessage name={name} component='small' className='error' />
        </div>
    );
}
export default memo(SelectField);