import React, { useContext, useEffect } from "react";
import Login from "./Login";
import { Routes, Route } from 'react-router-dom';
import PasswordReset from "./PasswordReset";
// import Register from "./Register";
import { AuthContext } from "../../helper/AuthContext";
import constants from "../../helper/Constants";

function AuthBase({ basePath }) {
    const { setAuthStatus, setUserName, setCreatedAt, setUserId, setProfilePic, setName, setShowLoader } = useContext(AuthContext);

    useEffect(() => {
        let authToken = localStorage.getItem("authToken");
        if (authToken !== undefined) {
            constants.serverGet('auth/verify-token').then((res) => {
                if (res.success === '1') {
                    setUserId(res.data.id);
                    setUserName(res.data.userName);
                    setProfilePic(res.data.profilePic);
                    setName(res.data.name);
                    setCreatedAt(res.data.createdAt);
                    setAuthStatus(true);
                    window.history.forward();
                }
                else {
                    localStorage.removeItem('authToken');
                }
                setShowLoader(false);
            });
        }
        else {
            setShowLoader(false);
        }
    }, []);

    return (
        <div className="page">
            <div className="">
                <div className="col col-login mx-auto">
                    <div className="text-center"> <img src="/logo.png" className="header-brand-img" alt="" /> </div>
                </div>
                <div className="container-1">
                    <div className="access-container p-6">
                        <Routes>
                            <Route path={"/"} element={<Login basePath={basePath} />} />
                            <Route path={"/login"} element={<Login basePath={basePath} />} />
                            <Route path={"/password-reset"} element={<PasswordReset basePath={basePath} />} />
                            {/* <Route path={"/register"} element={<Register basePath={basePath} />} /> */}
                            <Route path={"/*"} element={<Login basePath={basePath} />} />
                            {/* <Route path={'*'} element={<PageNotFound />} /> */}
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuthBase;