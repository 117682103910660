import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import FormField from '../../components/FormField';
import TextArea from '../../components/TextArea';
import { success, warning, error as toastrError } from 'toastr';
import constants from '../../helper/Constants';
import SelectField from '../../components/SelectField';
import BreadCrumb from '../../components/BreadCrumb';

function AddEditCoupon() {
    const todayDate = new Date();
    const validationSchema = Yup.object().shape({
        code: Yup.string().required("Coupon Code is a required field"),
        title: Yup.string().required("Title is a required field"),
        couponType: Yup.string().required("Coupon Type is a required field"),
        discount: Yup.number().typeError("Discount can be number or decimal value.").required("Title is a required field"),
        startDate: Yup.date().min(new Date(todayDate.setDate(todayDate.getDate() - 1)), "The start date can be in the future.").required("Start date is a required field"),
        endDate: Yup.date().min(todayDate, "The end date can be in the future.").required("End date is a required field"),
    });

    const initialValues = {
        code: "",
        title: "",
        discount: "",
        couponType: "",
        description: "",
        startDate: "",
        endDate: "",
    }

    const onSubmit = (data, { setSubmitting, resetForm, setValues }) => {
        constants.serverPost('coupon/save', data).then((res) => {
            setSubmitting(false);
            if (res.success === '1') {
                success(res.message);
                resetForm();
                setValues(initialValues);
            }
            else {
                warning(res.message);
            }
        }).catch((error) => {
            toastrError("The server is not responding, please try again later.");
            setSubmitting(false);
        });
    }

    const couponType = [
        {
            value: "",
            title: "Select coupon type",
        },
        {
            value: "0",
            title: "Flat",
        },
        {
            value: "1",
            title: "Percentage",
        },
    ]
    return (
        <>
            <BreadCrumb title={'Save Coupon'} />

            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8">
                    <div className="card">
                        <div className="card-body">
                            <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
                                {
                                    ({ isSubmitting }) => (
                                        <Form>
                                            <div className="mt-4">
                                                <div className="row">
                                                    <div className="col">
                                                        <FormField
                                                            label={"Coupon Code"}
                                                            name="code"
                                                            placeholder={"ex. ETEX200"}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <FormField
                                                            label={"Title"}
                                                            name="title"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <FormField
                                                            label={"Discount"}
                                                            name="discount"
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <SelectField
                                                            label={"CouponType"}
                                                            name="couponType"
                                                            options={couponType.map((op, index) => {
                                                                return (
                                                                    <option value={op.value}>{op.title}</option>
                                                                );
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <FormField
                                                            label={"Start Date"}
                                                            name="startDate"
                                                            type='date'
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <FormField
                                                            label={"End Date"}
                                                            name="endDate"
                                                            type='date'
                                                        />
                                                    </div>
                                                </div>
                                                <TextArea
                                                    label={"Description"}
                                                    name="description"
                                                />
                                                <div className="form-footer mb-5">
                                                    <Field type="submit" disabled={isSubmitting} className="btn btn-primary-light btn-block" value="Save" />
                                                </div>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddEditCoupon;