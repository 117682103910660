import FormField from './FormField';
import { Form, Formik } from 'formik';
function AddCurrencyModal(props) {
    return (
        <div className="modal fade" aria-hidden="true" id='add-forex-currency-modal'>
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content ">
                    <div className="modal-header">
                        <h4 className='mb-0'>Add forex currency</h4>
                    </div>
                    <Formik {...props}>
                        <Form>
                            <div className="modal-body pd-20">
                                <FormField label={"Currency Name"} name="currencyName" placeholder={"Enter forex currency name"} />
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className='btn btn-success btn-sm'>Save</button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
}
export default AddCurrencyModal;