import React, { useState } from 'react';
import FetchData from '../../components/FetchData';
import { NavLink } from 'react-router-dom';
import constants from '../../helper/Constants';
function ChatBotQuestion() {
    const [filterData, setFilterData] = useState({ process: false, index: null, data: null, action: 'change' });

    const getTable = (i, index) => {
        return (
            <tr key={index}>
                <td>{i.question}</td>
                <td>{i.answer}</td>
                <td>{new Date(i.createdAt).toLocaleString()}</td>
                <td>
                    <NavLink to={'/save-chat-bot-message?editId=' + i.id} className='btn btn-sm btn-primary mr-2'>Edit</NavLink>
                    <button className='btn btn-sm btn-danger' onClick={() => { constants.changeStatus("chat-bot-message/delete", i, `Do you want to delete?`, setFilterData, 'remove') }}>Delete</button>
                </td>
            </tr>
        )
    }

    return (
        <>
            <FetchData
                url='chat-bot-message'
                title="Chat Bot Question"
                subTitle="Question / Answer List"
                filterData={filterData}
                setFilterData={setFilterData}
                getTable={getTable}
                tableHeading={['Question', 'Answer', 'Created At', 'Action']} />
        </>
    );
}

export default ChatBotQuestion;