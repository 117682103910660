import React from "react";
import { NavLink } from 'react-router-dom';

function PasswordReset({basePath}) {
    return (
        <>
            <form className="login-form validate-form">
                <span className="login-form-title"> Password Reset </span>
                <div className="access-input validate-input" data-validate="Valid email is required: ex@abc.xyz">
                    <input className="login-input" type="text" name="email" placeholder="Email" />
                    <span className="input-focus"></span>
                    <span className="input-symbol">
                        <i className="zmdi zmdi-email" aria-hidden="true"></i>
                    </span>
                </div>
                <div className="">
                    <button type="submit" className="btn btn-primary-light btn-block">Send New Password</button>
                </div>
                <div className="text-center pt-3">
                    <p className="mb-0">Forget it, <NavLink to={basePath + '/login'} className="text-primary ml-1">send me back</NavLink> to the sign in screen.</p>
                </div>
            </form>
        </>
    );
}

export default PasswordReset;