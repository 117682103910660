import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { success, warning, error as toastrError } from 'toastr';
import constants from '../../helper/Constants';
import BreadCrumb from '../../components/BreadCrumb';

function DirectDeposit() {
    const validationSchema = Yup.object().shape({
        userName: Yup.string().required("Please enter username").min(4, 'Username too short').max(30, 'Username too long'),
        amount: Yup.number().typeError('Amount can be a number or decimal').required("Please enter amount"),
    });

    const initialValues = {
        userName: "",
        amount: "",
    }

    const onSubmit = (data, { setSubmitting, resetForm }) => {
        constants.serverPost('deposit/direct-deposit', data).then((res) => {
            setSubmitting(false);
            if (res.success === '1') {
                success(res.message);
                resetForm();
            }
            else {
                warning(res.message);
            }
        }).catch((error) => {
            toastrError("The server is not responding, please try again later.");
            setSubmitting(false);
        });
    }
    return (
        <>
            <BreadCrumb title={'Direct Deposit'} />

            <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
                                {
                                    ({ isSubmitting }) => (
                                        <Form>
                                            <div className="mt-4">
                                                <div className="form-group">
                                                    <label className="form-label" forhtml="userName">Enter username</label>
                                                    <Field type='text' className="form-control" name="userName" id="userName" placeholder="Ex. ET125..." autoComplete='off' />
                                                    <ErrorMessage component={"small"} className="error" name='userName' />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label" forhtml="amount">Enter amount</label>
                                                    <Field type='text' className="form-control" name="amount" id="amount" placeholder="Ex. 200.50" autoComplete='off' />
                                                    <ErrorMessage component={"small"} className="error" name='amount' />
                                                </div>
                                                <div className="form-footer mb-5">
                                                    <Field type='submit' className="btn btn-primary-light btn-block" value="Send" disabled={isSubmitting} />
                                                </div>
                                            </div>
                                        </Form>
                                    )

                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DirectDeposit;