import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import Dashboard from './Dashboard';
import DepositStatement from './DepositStatement';
import WithdrawalStatement from './WithdrawalStatement';
import Chat from './Chat';
import Users from './Users';
import Sidebar from '../../components/Sidebar';
import ActiveUsers from './ActiveUsers';
import BlockUsers from './BlockUsers';
import PendingWithdrawalStatement from './PendingWithdrawalStatement';
import AddEditCoupon from './AddEditCoupon';
import Coupons from './Coupons';
import ActiveCoupons from './ActiveCoupons';
import { SocketContext } from '../../helper/SocketContext';
import constants from '../../helper/Constants';
import AddEditBotQuestion from './AddEditBotQuestion';
import ChatBotQuestion from './ChatBotQuestion';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import CurrencyDataSocket from './CurrencyDataSocket';
import PendingDepositStatement from './PendingDepositStatement';
import UserProfile from './UserProfile';
import UpiList from './UpiList';
import AddEditUpi from './AddEditUpi';
import ForexCurrencyOtcData from './ForexCurrencyOtcData';
import DirectDeposit from './DirectDeposit';

function UserBase({ basePath }) {
    const [socket, setSocket] = useState(false);
    const [dataSocket, setDataSocket] = useState(false);
    const [newMessage, setNewMessage] = useState(null);
    const [pendingEmit, setPendingEmit] = useState([]);
    const [pendingDataEmit, setPendingDataEmit] = useState([]);
    const pageVisibility = useRef(true);
    const socketConnection = useRef(false);
    const dataSocketConnection = useRef(false);
    const emitMessage = (event, message) => {
        if (socketConnection.current.emit !== undefined) {
            socketConnection.current.emit(event, message);
        }
        else {
            setPendingEmit((oldEmitt) => [...oldEmitt, { event, message }]);
        }
        if (dataSocketConnection.current.emit !== undefined) {
            if (event == 'saveForexShareOCHL') {
                dataSocketConnection.current.emit('forexShareOCHL0', message);
                dataSocketConnection.current.emit('forexShareOCHL1', message);
                dataSocketConnection.current.emit('forexShareOCHL2', message);
                dataSocketConnection.current.emit('forexShareOCHL3', message);
                dataSocketConnection.current.emit('forexShareOCHL4', message);
                dataSocketConnection.current.emit('forexShareOCHL5', message);
                dataSocketConnection.current.emit('forexShareOCHL6', message);
            }
        }
        else {
            if (event == 'saveForexShareOCHL') {
                setPendingDataEmit((oldEmitt) => [...oldEmitt, { event: 'forexShareOCHL', message }]);
            }
        }
    }

    useEffect(() => {
        if (socket !== false) {
            socket.on("connect", () => {
                socketConnection.current = socket;
                pendingEmit.map((emitData) => {
                    return socket.emit(emitData.event, emitData.message);
                })
            });
            socket.on("connect_error", (error) => {
                // revert to classic upgrade
                socket.io.opts.transports = ["polling", "websocket"];
            });

            // newChatmessage
            socket.on('newMessage', (data) => {
                setNewMessage(data);
                // listen for visibility change events
                if (!pageVisibility.current || window.location.pathname !== '/chat') {
                    constants.notifify(data.userName + ": " + data.message, { type: 'newMessage' }).addEventListener('click', (event) => {
                        const existingWindow = window.open(window.location.origin + '/chat?room=' + data.roomId, "_blank");
                        if (existingWindow) {
                            existingWindow.focus();
                        } else {
                            window.open(window.location.origin + '/chat?room=' + data.roomId, "_blank");
                        }
                    });
                }
            });
        }
    }, [socket]);

    useEffect(() => {
        if (dataSocket !== false) {
            dataSocket.on("connect", () => {
                dataSocketConnection.current = dataSocket;
                pendingDataEmit.map((emitData) => {
                    return dataSocket.emit(emitData.event, emitData.message);
                })
            });
            dataSocket.on("connect_error", (error) => {
                // revert to classic upgrade
                dataSocket.io.opts.transports = ["polling", "websocket"];
            });
        }
    }, [dataSocket]);

    useEffect(() => {
        if (!socket) {
            setSocket(constants.socketIo());
        }

        if (!dataSocket) {
            setDataSocket(constants.dataSocketIo());
        }

        document.addEventListener("visibilitychange", function () {
            if (document.hidden) {
                pageVisibility.current = false;
            } else {
                pageVisibility.current = true;
            }
        });
    }, []);
    return (
        <SocketContext.Provider value={{ socket, pendingEmit, setPendingEmit, newMessage, setNewMessage }}>
            <div className="page">
                <div className="page-main">
                    <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
                    <Sidebar basePath={basePath} />
                    <div className="app-content">
                        <Header basePath={basePath} />
                        <div className="side-app">
                            <Routes>
                                <Route path={'/'} element={<Dashboard />} />
                                <Route path={'/change-password'} element={<ChangePassword />} />
                                <Route path={'/direct-deposit'} element={<DirectDeposit />} />
                                <Route path={'/deposit-statement'} element={<DepositStatement />} />
                                <Route path={'/pending-deposit-statement'} element={<PendingDepositStatement />} />
                                <Route path={'/withdrawal-statement'} element={<WithdrawalStatement />} />
                                <Route path={'/pending-withdrawal-statement'} element={<PendingWithdrawalStatement />} />
                                <Route path={'/users'} element={<Users />} />
                                <Route path={'/user-profile/:userId'} element={<UserProfile />} />
                                <Route path={'/active-users'} element={<ActiveUsers />} />
                                <Route path={'/blocked-users'} element={<BlockUsers />} />
                                <Route path={'/add-edit-coupon'} element={<AddEditCoupon />} />
                                <Route path={'/coupons'} element={<Coupons />} />
                                <Route path={'/active-coupons'} element={<ActiveCoupons />} />
                                <Route path={'/chat'} element={<Chat />} />
                                <Route path={'/save-chat-bot-message'} element={<AddEditBotQuestion />} />
                                <Route path={'/chat-bot-message'} element={<ChatBotQuestion />} />
                                <Route path={'/forex-currency-data'} element={<CurrencyDataSocket emitMessage={emitMessage} />} />
                                <Route path={'/forex-currency-otc-data'} element={<ForexCurrencyOtcData emitMessage={emitMessage} />} />
                                <Route path='/upi' element={<UpiList />} />
                                <Route path='/add-edit-upi' element={<AddEditUpi />} />
                                <Route path='/add-edit-upi/:editId' element={<AddEditUpi />} />
                                <Route path={'/*'} element={<Dashboard />} />
                            </Routes>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </SocketContext.Provider>
    );
}

export default UserBase;
