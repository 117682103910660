import { memo, useContext } from "react";
import { AuthContext } from "../helper/AuthContext";
import constants from "../helper/Constants";

function ChatUsers({ usersList, openChatHead, searchUser }) {
    const { userId } = useContext(AuthContext);
    return (
        <>
            <div className="col-md-12 col-lg-5 col-xl-4 chat">
                <div className="card">
                    <div className="p-4">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search" onKeyUp={(event) => {
                                searchUser(event);
                            }} />
                        </div>
                    </div>
                    <div className="contacts_body p-0 mb-3">
                        <ul className="contacts mb-0">
                            {(usersList.length >= 1) ? usersList.map((value, key) => {
                                return (
                                    <li className="active" key={key} onClick={() => {
                                        openChatHead(value);
                                    }}>
                                        <div className="d-flex bd-highlight">
                                            <div className="img_cont">
                                                <img src={process.env.REACT_APP_SERVER_BASE_URL+"uploads/"+ (userId !== value.senderId) ? value['sender.profilePic'] : value['receiver.profilePic']} onError={constants.defaultImage} data-src="/assets/images/user.png" className="rounded-circle user_img" alt="img" />
                                            </div>
                                            <div className="user_info">
                                                <h6 className="mt-1 mb-0 ">{(userId !== value.senderId) ? (value['sender.name'] !== '' ? value['sender.name'] : value['sender.userName']) : value['receiver.name'] !== '' ? value['receiver.name'] : value['receiver.userName']} { }</h6>
                                                <span className={`dot-label bg-${((userId !== value.senderId) ? value['sender.activeStatus'] : value['receiver.activeStatus']) === '1' ? 'success' : 'danger'}`}></span>
                                                <span className="mr-3">{((userId !== value.senderId) ? value['sender.activeStatus'] : value['receiver.activeStatus']) === '1' ? 'online' : 'offline'}</span>
                                            </div>
                                            <div className="float-right text-right ml-auto mt-auto mb-auto">
                                                <small>{value.unReaded > 0 ? (<span className="unreaded-message">{value.unReaded}</span>) : ''}{constants.getTimeAgo(value.createdAt)}</small>
                                            </div>
                                        </div>
                                    </li>
                                );
                            }) : (
                                <li className="active">
                                    <div className="d-flex bd-highlight">
                                        <div className="img_cont">
                                            <img src="../assets/images/user.png" className="rounded-circle user_img" alt="img" />
                                        </div>
                                        <div className="user_info">
                                            <h6 className="mt-1 mb-0 ">No Record found</h6>
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(ChatUsers);