import { memo } from "react";
import { Formik, Form, Field } from 'formik';

function ChatMessageBox({sendMessage}) {
    const initialValues = {
        message: "",
    }
    return (
        <div className="card-footer">
            <Formik initialValues={initialValues} onSubmit={sendMessage}>
                {
                    ({ isSubmitting }) => {
                        return (
                            <>
                                <Form className="msb-reply d-flex">
                                    <span className="input-group-text attach_btn">
                                        <i className="fa fa-smile-o"></i>
                                    </span>
                                    <Field name='message' placeholder="What's on your mind..."></Field>
                                    <Field as="button" type="submit" disabled={isSubmitting}>
                                        <i className="fa fa-paper-plane-o"></i>
                                    </Field>
                                </Form>
                            </>
                        );
                    }
                }
            </Formik>
        </div>
    );
}
export default memo(ChatMessageBox);