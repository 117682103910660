function RenderTradings({ tradings, livePrice }) {
    const tradeTime = Object.keys(tradings || { 'no': [] });
    return (
        <table className="table card-table border table-vcenter text-nowrap align-items-center text-center" style={{ background: 'black' }}>
            <tbody>
                {tradeTime.map((gT, index) => {
                    if (gT !== 'no') {
                        const d = new Date(parseFloat(gT));
                        return (
                            <tr key={index}>
                                <td>{d.toString()}</td>
                                <td>
                                    <table>
                                        <tbody>
                                            {tradings[gT].map((data) => (
                                                <tr key={data.id}>
                                                    <td>{data.buySell === '0' ? 'Buy' : 'Sell'}</td>
                                                    <td>{data.investmentAmount}</td>
                                                    <td>{data.buySell === '0' ? (livePrice > data.currentPrice ? 'Win' : 'Lose') : (livePrice < data.currentPrice ? 'Win' : 'Lose')}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        );
                    }
                    else {
                        return '';
                    }
                })}
            </tbody>
        </table>
    );
}

export default RenderTradings;