import React, { useState } from 'react';
import FetchData from '../../components/FetchData';
import constants from '../../helper/Constants';
import { Link } from 'react-router-dom';
function WithdrawalStatement({ userId }) {
    const [filterData, setFilterData] = useState({ process: false, index: null, data: null, action: 'change' });
    const inputWrapper = constants.createElement('div', { className: ['form-group', 'mb-2'] });
    const input = constants.createElement('input', { className: ['swal-content__input'], attributes: { name: "txnId", placeholder: "Enter transaction id", 'data-message': 'Transaction ID is required.' } });
    inputWrapper.appendChild(input);
    const getTable = (i, index) => {
        return (
            <tr key={index}>
                <td><Link to={'/user-profile/' + i?.userId} className='r-link'>{(i?.user?.userName||"") + ((i?.user?.name) ? ' (' + i.user.name + ')' : '')}</Link></td>
                <td>{i.txnId}</td>
                <td>{i.amount}</td>
                <td>{i.charges}</td>
                <td>{i.finalAmount}</td>
                <td>{i.account.accountHolderName}</td>
                <td>{i.account.accountNo}</td>
                <td>{i.account.ifscCode}</td>
                <td>{i.account.bankName}</td>
                <td>{i.account.upi}</td>
                <td>{new Date(i.createdAt).toLocaleString()}</td>
                <td>
                    {(i.status === '0') ? (
                        <>
                            <button type='button' onClick={() => {
                                i.status = '1';
                                constants.changeStatus('withdrawal/verify-withdrawal', i, "Once accepted, you will not be able to recover!", setFilterData, 'change', {
                                    text: 'Enter transaction ID.',
                                    buttonText: "Save",
                                    content: inputWrapper,
                                });
                            }} className={`tag tag-green mr-1`}>Accept</button>
                            <button type='button' onClick={() => {
                                i.status = '2';
                                constants.changeStatus('withdrawal/verify-withdrawal', i, "Once rejected, you will not be able to recover!", setFilterData, 'change');
                            }} className={`tag tag-red`}>Reject</button>
                        </>
                    ) : (
                        <span className={`tag tag-${(i.status === '1') ? 'green' : 'red'}`}>{(i.status === '1') ? 'Accepted' : 'Rejected'}</span>
                    )}
                </td>
            </tr>
        );
    }

    return (
        <>
            <FetchData
                url='withdrawal/get-withdrawals'
                title="All Withdrawals"
                subTitle="Withdrawal List"
                filterData={filterData}
                setFilterData={setFilterData}
                getTable={getTable}
                data={(userId !== undefined) ? { userId } : {}}
                tableHeading={['User', 'Transaction ID', 'Amount', 'Charges', 'Final Amount', 'Account Holder Name', 'Account No.', 'IFSC Code', 'Bank Name', 'UPI ID', 'Transaction Time', 'Status']} />
        </>
    );
}

export default WithdrawalStatement;