import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { success, warning, error as toastrError } from 'toastr';
import constants from '../../helper/Constants';
import BreadCrumb from '../../components/BreadCrumb';

function ChangePassword() {
    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string().required("Old password is a required field").min(4, 'Old password must be at least 4 characters').max(20, 'Old password must be at most 20 characters'),
        newPassword: Yup.string().required("New password is a required field").min(4, 'New password must be at least 4 characters').max(20, 'New password must be at most 20 characters'),
        matchPassword: Yup.string().required("Passwords must match").oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    });

    const initialValues = {
        currentPassword: "",
        newPassword: "",
        matchPassword: "",
    }

    const onSubmit = (data, { setSubmitting, resetForm }) => {
        constants.serverPost('user/change-password', data).then((res) => {
            setSubmitting(false);
            if (res.success === '1') {
                success(res.message);
                resetForm();
            }
            else {
                warning(res.message);
            }
        }).catch((error) => {
            toastrError("The server is not responding, please try again later.");
            setSubmitting(false);
        });
    }
    return (
        <>
            <BreadCrumb title={'Change Password'} />

            <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
                                {
                                    ({ isSubmitting }) => (
                                        <Form>
                                            <div className="mt-4">
                                                <div className="form-group">
                                                    <label className="form-label" forhtml="currentPassword">Enter Old Password</label>
                                                    <Field type='password' className="form-control" name="currentPassword" id="currentPassword" placeholder="Enter Old Password" autoComplete='off' />
                                                    <ErrorMessage component={"small"} className="error" name='currentPassword' />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label" forhtml="newPassword">Enter New Password</label>
                                                    <Field type='password' className="form-control" name="newPassword" id="newPassword" placeholder="Enter New Password" autoComplete='off' />
                                                    <ErrorMessage component={"small"} className="error" name='newPassword' />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label" forhtml="matchPassword">Re-enter New Password</label>
                                                    <Field type='password' className="form-control" name="matchPassword" id="matchPassword" placeholder="Re-enter New Password" autoComplete='off' />
                                                    <ErrorMessage component={"small"} className="error" name='matchPassword' />
                                                </div>
                                                <div className="form-footer mb-5">
                                                    <Field type='submit' className="btn btn-primary-light btn-block" value="Save Password" disabled={isSubmitting} />
                                                </div>
                                            </div>
                                        </Form>
                                    )

                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;