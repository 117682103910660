import React, { useContext, memo } from 'react';
import { AuthContext } from '../helper/AuthContext';
import constants from '../helper/Constants';
function Chat({ chatHead }) {
    const { userId } = useContext(AuthContext);
    return (
        <div className="action-header clearfix">
            <div className="float-left hidden-xs d-flex ml-2">
                <div className="img_cont mr-3">
                    <img src={process.env.REACT_APP_SERVER_BASE_URL+"uploads/"+ (chatHead.senderId !== userId) ? chatHead['sender.profilePic'] : chatHead['receiver.profilePic']} onError={constants.defaultImage} data-src="/assets/images/user.png" className="rounded-circle user_img" alt="img" />
                </div>
                <div className="align-items-center mt-2"> <h4 className="mb-0 font-weight-semibold">{(chatHead.senderId !== userId) ? ((chatHead['sender.name'] !== '') ? chatHead['sender.name'] : chatHead['sender.userName']) : ((chatHead['receiver.name'] !== '') ? chatHead['receiver.name'] : chatHead['receiver.userName'])}</h4>
                    <span className={`dot-label bg-${(((chatHead.senderId !== userId) ? chatHead['sender.activeStatus'] : chatHead['receiver.activeStatus']) === '1') ? 'success' : 'danger'}`}></span>
                    <span className="mr-3">{(((chatHead.senderId !== userId) ? chatHead['sender.activeStatus'] : chatHead['receiver.activeStatus']) === '1') ? 'online' : 'offline'}</span>
                </div>
            </div>
        </div>
    );
}

export default memo(Chat);