import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import FormField from '../../components/FormField';
import { success, warning, error as toastrError } from 'toastr';
import constants from '../../helper/Constants';
import BreadCrumb from '../../components/BreadCrumb';
import FileField from '../../components/FileField';
import { useParams } from 'react-router-dom';

function AddEditUpi() {
    const editId = useParams().editId;
    const formRef = useRef();

    const validationSchema = Yup.object().shape({
        appName: Yup.string().required("UPI app name is a required field"),
        upi: Yup.string().required("UPI ID is a required field"),
    });

    const initialValues = {
        appName: '',
        upi: '',
        qrCode: '',
        qrCodeFile: null,
        appLogo: '',
        appLogoFile: null,
    };

    const onSubmit = (data, { setSubmitting, resetForm, setValues }) => {
        if (editId !== undefined) {
            data.editId = editId;
        }
        constants.multiPart('upi/save', constants.formData(data)).then((res) => {
            setSubmitting(false);
            if (res.success === '1') {
                success(res.message);
                if (editId === undefined) {
                    resetForm();
                    setValues(initialValues);
                }
            }
            else {
                warning(res.message);
            }
        }).catch((error) => {
            toastrError("The server is not responding, please try again later.");
            setSubmitting(false);
        });
    }

    useEffect(() => {
        if (editId !== undefined) {
            constants.serverGet('upi/' + editId).then((res) => {
                if (res.success === '1') {
                    formRef.current.setFieldValue('upi', res.data.upi);
                    formRef.current.setFieldValue('appName', res.data.appName);
                }
            })
        }
    }, [editId])
    return (
        <>
            <BreadCrumb title={'Save Upi ID'} />

            <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema} innerRef={formRef}>
                                {
                                    ({ isSubmitting, setFieldValue }) => (
                                        <Form>
                                            <div className="mt-4">
                                                <FormField
                                                    label={"Upi App Name"}
                                                    name="appName"
                                                    placeholder={'ex. gPay'} />

                                                <FileField
                                                    label={"Upi App logo"}
                                                    name="appLogo"
                                                    setFieldValue={setFieldValue}
                                                />
                                                <FormField
                                                    label={"Upi ID"}
                                                    name="upi"
                                                    placeholder={"ex. abc@oksbi"}
                                                />
                                                <FileField
                                                    label={"QR Code"}
                                                    name="qrCode"
                                                    setFieldValue={setFieldValue}
                                                />
                                                <div className="form-footer mb-5">
                                                    <Field type="submit" disabled={isSubmitting} className="btn btn-primary-light btn-block" value="Save" />
                                                </div>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddEditUpi;