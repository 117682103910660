import { memo } from "react";
import { NavLink } from "react-router-dom";

function Widget({ title, value, subTitle, textClass, url }) {
    return (
        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <NavLink to={url}>
                <div className="card p-3 px-4">
                    <div className="text-center">{title}</div>
                    <div className={`py-4 m-0 text-center h1 ${textClass ?? 'text-secondary-1'}`}>{value}</div>
                    <div className="d-flex">
                        <small className="text-muted">{subTitle ?? ""}</small>
                    </div>
                </div>
            </NavLink>
        </div>
    );
}
export default memo(Widget);