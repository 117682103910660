import { memo, useContext } from "react";
import { AuthContext } from "../helper/AuthContext";
import constants from "../helper/Constants";
function SenderMessage({ chatItem }) {
    const { profilePic } = useContext(AuthContext);
    return (
        <div className="d-flex justify-content-end">
            <div className="msg_cotainer_send"> {chatItem.message}
                <span className="msg_time_send">{constants.getTimeAgo(chatItem.createdAt)}</span>
            </div>
            <div className="img_cont_msg">
                <img src={process.env.REACT_APP_SERVER_BASE_URL+"uploads/"+ profilePic ?? ''} data-src="/assets/images/user.png" onError={constants.defaultImage} className="rounded-circle user_img_msg" alt="img" />
                {/* <img src={profilePic} data-src="/assets/images/user.png" onError={constants.defaultImage} className="rounded-circle user_img_msg" alt="img" /> */}
            </div>
        </div>
    );
}
export default memo(SenderMessage);