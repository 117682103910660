import React, { useReducer, useEffect, useState } from 'react';
import constants from '../../helper/Constants';
import * as Yup from 'yup';
import { error, success } from 'toastr';
import BreadCrumb from '../../components/BreadCrumb';
import CurrencyCard from '../../components/CurrencyCard';
import AddCurrencyModal from '../../components/AddCurrencyModal';
const setCurrencyDataReducer = (state, action) => {
    switch (action.action) {
        case 'FOREX_INIT': {
            var state = {};
            action.data.map((d) => {
                state[d.currencyName] = {
                    unixTime: (d.forexCurrencyData !== undefined && d.forexCurrencyData !== null && d.forexCurrencyData.length > 0) ? d.forexCurrencyData[0].unixTime : 0,
                    price: (d.forexCurrencyData !== undefined && d.forexCurrencyData !== null && d.forexCurrencyData.length > 0) ? d.forexCurrencyData[0].price : 0,
                    id: d.id,
                }
                return;
            });
            return state;
        }
        case 'FOREX_UPDATE_RATE': {
            const currencyName = (action.data.p).replace('/', '-');
            if (state[currencyName] !== undefined) {
                if (state[currencyName].unixTime !== action.data.t) {
                    action.emitMessage('saveForexShareOCHL', {
                        forexCurrencyId: state[currencyName].id,
                        price: state[currencyName].price,
                        unixTime: state[currencyName].unixTime,
                    });
                }
                state[currencyName].unixTime = action.data.t;
                state[currencyName].price = action.data.a;

                action.emitMessage('forexShareOCHL', {
                    forexCurrencyId: state[currencyName].id,
                    price: state[currencyName].price,
                    unixTime: state[currencyName].unixTime,
                    currencyName
                });
            }
            return state;
        }
        case 'FOREX_UPDATE': {
            for (var currencyName in state) {
                if (state[currencyName].id === action.data.id) {
                    state[currencyName] = {
                        unixTime: (action.data.forexCurrencyData !== undefined && action.data.forexCurrencyData !== null && action.data.forexCurrencyData.length > 0) ? action.data.forexCurrencyData[0].unixTime : 0,
                        price: (action.data.forexCurrencyData !== undefined && action.data.forexCurrencyData !== null && action.data.forexCurrencyData.length > 0) ? action.data.forexCurrencyData[0].price : 0,
                        id: action.data.id,
                    };
                }
            }
            return state;
        }
        case 'FOREX_INSERT': {
            state[action.data.currencyName] = {
                unixTime: (action.data.forexCurrencyData !== undefined && action.data.forexCurrencyData !== null && action.data.forexCurrencyData.length > 0) ? action.data.forexCurrencyData[0].unixTime : 0,
                price: (action.data.forexCurrencyData !== undefined && action.data.forexCurrencyData !== null && action.data.forexCurrencyData.length > 0) ? action.data.forexCurrencyData[0].price : 0,
                id: action.data.id,
            };
            return state;
        }
        default: {
            return state;
        }
    }
}
const initialCurrencyData = { items: [] };

const setItemReducer = (state, action) => {
    switch (action.action) {
        case 'FOREX_INIT': {
            return action.data;
        }
        case 'FOREX_UPDATE_RATE': {
            const currencyName = (action.data.p).replace('/', '-');
            return state.map((stateItem, index) => {
                if (stateItem.currencyName === currencyName) {
                    return { ...stateItem, livePrice: action.data.a };
                }
                else {
                    return stateItem;
                }
            });
        }
        case 'FOREX_UPDATE': {
            return state.map((stateItem, index) => {
                if (stateItem.id === action.data.id) {
                    return action.data;
                }
                else {
                    return stateItem;
                }
            });
        }
        case 'FOREX_INSERT': {
            if (state.items)
                return [...state.items, action.data];
            else
                return [action.data];
        }
        default: {
            return state;
        }
    }
}
const initialItems = [];
var forexSocket = null;
function CurrencyDataSocket3({ emitMessage }) {
    const [socketConnection, setSocketConnection] = useState('Loading...');
    const [currencyData, setCurrencyData] = useReducer(setCurrencyDataReducer, initialCurrencyData);
    const [dataItems, setDataItems] = useReducer(setItemReducer, initialItems);

    const updateForexCurrency = (data) => {
        forexSocket.send(JSON.stringify({ action: 'unsubscribe', params: 'C.C:' + data.currencyName }));
        constants.serverPost('forex-currency/update', data).then((res) => {
            if (res.success === '1') {
                setCurrencyData({ action: 'FOREX_UPDATE', data: res.data });
                setDataItems({ action: 'FOREX_UPDATE', data: res.data });
                if (res.data.status === '1') {
                    forexSocket.send(JSON.stringify({ action: 'subscribe', params: 'C.C:' + res.data.currencyName }));
                }
                success(res.message);
            }
            else {
                error(res.message || "Something went wrong, please try again later.");
            }
        })
    }

    useEffect(() => {
        forexSocket = constants.forexSocket();
        forexSocket.addEventListener('message', (event) => {
            var data = JSON.parse(event.data);
            if (data[0].status === 'connected') {
                console.log("Connected")
                setSocketConnection('Connected Successfully');
                forexSocket.send(JSON.stringify({ action: 'auth', params: 'OarAwdiiOLvrrGPVRutJxs927S8Opm0B' }));
            }
            if (data[0].status === 'auth_failed') {
                setSocketConnection('Authentication failed');
            }
            else if (data[0].status === 'auth_success') {
                setSocketConnection('Authorized!');
                constants.serverGet('forex-currency/get-currencies', { type: '0' }).then((data) => {
                    setSocketConnection(false);
                    if (data.success === '1') {
                        setCurrencyData({ action: 'FOREX_INIT', data: data.data });
                        setDataItems({ action: 'FOREX_INIT', data: data.data });
                        data.data.map((currencyItem) => {
                            if (currencyItem.status === '1') {
                                forexSocket.send(JSON.stringify({ action: 'subscribe', params: 'C.C:' + currencyItem.currencyName }));
                            }
                        })
                    }
                });
            }
            else if (data[0].status === 'auth_timeout') {
                setSocketConnection(data[0].message);
            }
            else if (data[0].p !== undefined) {
                data.map((forexRate) => {
                    setCurrencyData({ action: 'FOREX_UPDATE_RATE', data: forexRate, emitMessage: emitMessage });
                    setDataItems({ action: 'FOREX_UPDATE_RATE', data: forexRate, emitMessage: emitMessage });
                })
            }
        });

        constants.serverPost('otc-status/save', {
            status: '0',
        }).then((res) => {
            if (res.success === '1') {
                success(res.message || 'Record updated successfully.');
            }
            else {
                error(res.message || 'Something went wrong, please try again.');
            }
        });

        return () => {
            // Clean up the WebSocket connection and event listener
            forexSocket.removeEventListener('message', () => {

            });
            forexSocket.close();
        };
    }, []);

    const initialValues = {
        currencyName: "",
    }

    const onSubmit = (data, { resetForm }) => {
        data.type = '0';
        constants.serverPost('forex-currency/save', data).then((res) => {
            if (res.success === '1') {
                setCurrencyData({ action: 'FOREX_INSERT', data: res.data });
                setDataItems({ action: 'FOREX_INSERT', data: res.data });
                forexSocket.send(JSON.stringify({ action: 'subscribe', params: 'C.C:' + res.data.currencyName }));
                success(res.message);
                resetForm();
            }
            else {
                error(res.message || "Something went wrong, please try again later.");
            }
        })
    }

    const validationSchema = Yup.object({
        currencyName: Yup.string().required("Forex currency name is required").min(5, 'Currency Name must be at least 5 characters'),
    });

    return (
        <>
            <BreadCrumb title={'Currency Price'} />

            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <h3 className="card-title">Live Prices</h3>
                            <button className="btn btn-outline-primary btn-sm" data-target="#add-forex-currency-modal" data-toggle="modal"><i className="fa fa-plus"></i> Add Currency</button>
                        </div>
                        <div className="card-body">
                            <div className="grid-margin">
                                <div className="row">
                                    {socketConnection ? (
                                        <div className='w-100 text-center'>
                                            <h2 className='mt-5 mb-5'>{socketConnection}</h2>
                                        </div>
                                    ) : ''}
                                    {dataItems.map((value, i) => {
                                        return (
                                            <CurrencyCard {...{ value, i, updateForexCurrency, setCurrencyData, setDataItems }} key={i} />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddCurrencyModal initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} />
        </>
    );
}

export default CurrencyDataSocket3;