import { memo, useContext } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from "../helper/AuthContext";
function Sidebar({ basePath }) {
    const { setAuthStatus } = useContext(AuthContext);
    const navigate = useNavigate();
    return (
        <aside className="app-sidebar app-sidebar2">
            <NavLink className="header-brand left-meu-header-brand" to={basePath + "/"}>
                <img src="/logo.png" className="header-brand-img desktop-logo"
                    alt="Earn Tex Logo" />
                <img src="/logo.png" className="header-brand-img mobile-view-logo"
                    alt="Earn Tex Logo" />
            </NavLink>
            <ul className="side-menu">
                <li>
                    <NavLink className="side-menu__item" to={basePath + "/"}>
                        <i className="side-menu__icon fa fa-tachometer"></i>
                        <span className="side-menu__label">Dashboard</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="side-menu__item" to={basePath + "/forex-currency-data"}>
                        <i className="side-menu__icon fa fa-tachometer"></i>
                        <span className="side-menu__label">Forex Data</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="side-menu__item" to={basePath + "/forex-currency-otc-data"}>
                        <i className="side-menu__icon fa fa-tachometer"></i>
                        <span className="side-menu__label">Forex OTC Data</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="side-menu__item" to={basePath + "/change-password"}>
                        <i className="side-menu__icon fa fa-lock"></i>
                        <span className="side-menu__label">Change Password</span>
                    </NavLink>
                </li>
                <li className="slide">
                    <a className="side-menu__item" data-toggle="slide" href="#">
                        <i className="side-menu__icon fa fa-paste"></i>
                        <span className="side-menu__label">Deposit</span>
                        <i className="angle fa fa-angle-right"></i>
                    </a>
                    <ul className="slide-menu">
                        <li>
                            <NavLink className="slide-item" to={basePath + "/direct-deposit"}>Direct Deposit</NavLink>
                            <NavLink className="slide-item" to={basePath + "/deposit-statement"}>All Deposit</NavLink>
                            <NavLink className="slide-item" to={basePath + "/pending-deposit-statement"}>Pending Deposit</NavLink>
                        </li>
                    </ul>
                </li>
                <li className="slide">
                    <a className="side-menu__item" data-toggle="slide" href="#">
                        <i className="side-menu__icon fa fa-paste"></i>
                        <span className="side-menu__label">Withdrawal</span>
                        <i className="angle fa fa-angle-right"></i>
                    </a>
                    <ul className="slide-menu">
                        <li>
                            <NavLink className="slide-item" to={basePath + "/withdrawal-statement"}>All Withdrawal</NavLink>
                            <NavLink className="slide-item" to={basePath + "/pending-withdrawal-statement"}>Pending Withdrawal</NavLink>
                        </li>
                    </ul>
                </li>

                <li className="slide">
                    <a className="side-menu__item" data-toggle="slide" href="#">
                        <i className="side-menu__icon fa fa-users"></i>
                        <span className="side-menu__label">Users</span>
                        <i className="angle fa fa-angle-right"></i>
                    </a>
                    <ul className="slide-menu">
                        <li>
                            <NavLink className="slide-item" to={basePath + "/users"}>All Users</NavLink>
                            <NavLink className="slide-item" to={basePath + "/active-users"}>Active Users</NavLink>
                            <NavLink className="slide-item" to={basePath + "/blocked-users"}>Blocked Users</NavLink>
                        </li>
                    </ul>
                </li>

                <li className="slide">
                    <a className="side-menu__item" data-toggle="slide" href="#">
                        <i className="side-menu__icon fa fa-gift"></i>
                        <span className="side-menu__label">Coupon</span>
                        <i className="angle fa fa-angle-right"></i>
                    </a>
                    <ul className="slide-menu">
                        <li>
                            <NavLink className="slide-item" to={basePath + "/add-edit-coupon"}>Add Coupon</NavLink>
                            <NavLink className="slide-item" to={basePath + "/coupons"}>Coupons</NavLink>
                            <NavLink className="slide-item" to={basePath + "/active-coupons"}>Active Coupons</NavLink>
                        </li>
                    </ul>
                </li>
                <li className="slide">
                    <a className="side-menu__item" data-toggle="slide" href="#">
                        <i className="side-menu__icon fa fa-gift"></i>
                        <span className="side-menu__label">Chat Bot Qestion</span>
                        <i className="angle fa fa-angle-right"></i>
                    </a>
                    <ul className="slide-menu">
                        <li>
                            <NavLink className="slide-item" to={basePath + "/save-chat-bot-message"}>Add Question</NavLink>
                            <NavLink className="slide-item" to={basePath + "/chat-bot-message"}>Question List</NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink className="side-menu__item" to={basePath + "/chat"}>
                        <i className="side-menu__icon fe fe-mail"></i>
                        <span className="side-menu__label">Chat</span>
                    </NavLink>
                </li>
                <li className="slide">
                    <a className="side-menu__item" data-toggle="slide" href="#">
                        <i className="side-menu__icon glyphicon glyphicon-qrcode"></i>
                        <span className="side-menu__label">Upi IDs</span>
                        <i className="angle fa fa-angle-right"></i>
                    </a>
                    <ul className="slide-menu">
                        <li>
                            <NavLink className="slide-item" to={basePath + "/add-edit-upi"}>Add Coupon</NavLink>
                            <NavLink className="slide-item" to={basePath + "/upi"}>Upi IDs</NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <button type='button' className="side-menu__item text-white px-4 w-100 text-left" onClick={() => {
                        localStorage.removeItem('authToken');
                        setAuthStatus(false);
                        navigate('/auth/login');
                    }}>
                        <i className="side-menu__icon fa fa fa-power-off"></i>
                        <span className="side-menu__label ml-1">Logout</span>
                    </button>
                </li>
            </ul>
        </aside>
    );
}
export default memo(Sidebar);