import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { error as toastrError } from 'toastr';
import Constants from '../../helper/Constants';
import DocumentObject from '../../components/DocumentObject';
import BreadCrumb from '../../components/BreadCrumb';
import LargeImageModal from '../../components/LargeImageModal';
import WithdrawalStatement from './WithdrawalStatement';
import PendingWithdrawalStatement from './PendingWithdrawalStatement';
import DepositStatement from './DepositStatement';
import PendingDepositStatement from './PendingDepositStatement';
function UserProfile() {
    const { userId } = useParams();
    const [activeNav, setActiveNav] = useState({
        title: 'Withdrawal',
        active: true,
        component: <WithdrawalStatement userId={userId} />
    });
    const [navigationList, setNavigationList] = useState([
        {
            title: 'Withdrawal',
            active: true,
            component: <WithdrawalStatement userId={userId} />
        },
        {
            title: 'Pending Withdrawal',
            active: false,
            component: <PendingWithdrawalStatement userId={userId} />
        },
        {
            title: 'Deposit',
            active: false,
            component: <DepositStatement userId={userId} />
        },
        {
            title: 'Pending Deposit',
            active: false,
            component: <PendingDepositStatement userId={userId} />
        },
    ]);
    const [userData, setUserData] = useState(null);

    const clickHandler = (name, status) => {
        Constants.serverPut('document/verify', { name, status, userId: userId }).then((res) => {
            if (res.success === '1') {
                setUserData((oldData) => { return { ...oldData, ...{ document: { ...oldData.document, ...res.data } } } });
            }
            else {
                toastrError(res.message || 'Something went wrong, please try agian later.');
            }
        }).catch((error) => {
            toastrError(error.message || 'Something went wrong, please try agian later.');
        });
    }

    useEffect(() => {
        Constants.serverGet('user/get-user-profile', { id: userId }).then((res) => {
            if (res.success === '1') {
                setUserData(() => res.data);
            }
            else {
                toastrError(res.message || 'Something went wrong, please try agian later.');
            }
        }).catch((error) => {
            toastrError(error.message || 'Something went wrong, please try agian later.');
        });
    }, [userId]);

    return (
        <>
            <BreadCrumb title='User Profile' />
            <div className="row">
                {(userData !== null) ? (
                    <div className="col-md-12 col-lg-12">
                        <div className="card">
                            <div className="card-header"> <h3 class="card-title">{userData.userName + ((userData.name !== '') ? ` (${userData.name})` : '')}</h3> </div>
                            <div className="card-body">
                                <div className="wideget-user mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="wideget-user-desc d-flex">
                                                <div className="wideget-user-img text-center mr-5">
                                                    <img class="mr-0 mb-4" src={userData.profilePic} alt="img" onError={Constants.defaultImage} data-src='/assets/images/user.png' />
                                                    <h4>{userData.userName + ((userData.name !== '') ? ` (${userData.name})` : '')}</h4>
                                                    <p>{userData.email}</p>
                                                    <h6 class="text-muted mb-3">Registration : {Constants.getTimeAgo(userData.createdAt)}</h6>
                                                </div>
                                                <div className="user-wrap">
                                                    <div class="row gutters-xs">
                                                        <div class="col">Active Status</div>
                                                        <span class="col-auto">
                                                            <button class={"btn btn-sm btn-" + (userData.activeStatus === '1' ? 'success' : 'danger') + "-light"} type="button">{userData.activeStatus === '1' ? 'Online' : 'Off-Line'}</button>
                                                        </span>
                                                    </div>
                                                    <div class="row gutters-xs mt-3">
                                                        <div class="col">Profile Status</div>
                                                        <span class="col-auto">
                                                            <button class={"btn btn-sm btn-" + (userData.verifyStatus === '1' ? 'success' : 'warning') + "-light"} type="button">{userData.verifyStatus === '1' ? 'Approved' : 'Pending'}</button>
                                                        </span>
                                                    </div>
                                                    <div class="row gutters-xs mt-3">
                                                        <div class="col">Block Status</div>
                                                        <span class="col-auto">
                                                            <button class={"btn btn-sm btn-" + (userData.block === '1' ? 'danger' : 'success') + "-light"} type="button">{userData.block === '1' ? 'Blocked' : 'Un-Blocked'}</button>
                                                        </span>
                                                    </div>

                                                    <div class="row gutters-xs mt-3">
                                                        <div class="col">Account Type</div>
                                                        <span class="col-auto">
                                                            <button class={"btn btn-sm btn-primary-light"} type="button">{userData.accountType === '0' ? 'User' : (userData.accountType === '1' ? 'Admin' : 'Dummy')}</button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <table className='table card-table border table-vcenter text-nowrap align-items-center'>
                                                <tbody>
                                                    <tr>
                                                        <th>Wallet</th>
                                                        <td>{userData.wallet}/-</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Withdrawal</th>
                                                        <td>{userData.withdrawalAmount}/-</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Withdrawal Count</th>
                                                        <td>{userData.withdrawalCount}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Deposit</th>
                                                        <td>{userData.depositAmount}/-</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Deposit Count</th>
                                                        <td>{userData.depositCount}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                {(userData.document !== null && userData !== undefined) ? (
                                    <div className="row mt-4">
                                        <div className="col-xs-6 col-md-3 mb-5">
                                            <DocumentObject data={{
                                                src: userData.document.aadharCard,
                                                status: userData.document.aadharCardStatus,
                                                title: 'Aadhar Card',
                                                name: 'aadharCardStatus',
                                            }} clickHandler={clickHandler} />
                                        </div>
                                        <div className="col-xs-6 col-md-3 mb-5">
                                            <DocumentObject data={{
                                                src: userData.document.panCard,
                                                status: userData.document.panCardStatus,
                                                title: 'Pan Card',
                                                name: 'panCardStatus',
                                            }} clickHandler={clickHandler} />
                                        </div>
                                        <div className="col-xs-6 col-md-3 mb-5">
                                            <DocumentObject data={{
                                                src: userData.document.signature,
                                                status: userData.document.signatureStatus,
                                                title: 'Signature',
                                                name: 'signatureStatus',
                                            }} clickHandler={clickHandler} />
                                        </div>
                                        <div className="col-xs-6 col-md-3 mb-5">
                                            <DocumentObject data={{
                                                src: userData.document.photo,
                                                status: userData.document.photoStatus,
                                                title: 'Photo',
                                                name: 'photoStatus',
                                            }} clickHandler={clickHandler} />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                    </>
                                )}
                            </div>
                            <div className="border-top">
                                <div className="wideget-user-tab">
                                    <div className="tab-menu-heading">
                                        <div className="tabs-menu1 js-tabs">
                                            <ul class="nav">
                                                {(navigationList.length > 0) ? navigationList.map((nav, index) => {
                                                    return (
                                                        <>
                                                            <li class="" key={index}>
                                                                <a className={((nav.active) ? 'active' : '')} onClick={() => {
                                                                    if (!nav.active) {
                                                                        setActiveNav(nav);
                                                                        setNavigationList((oldNav) => oldNav.map((a, ind) => {
                                                                            if (index === ind)
                                                                                return { ...a, active: true }
                                                                            else
                                                                                return { ...a, active: false }

                                                                        }))
                                                                    }
                                                                }}>{nav.title}</a>
                                                            </li>
                                                        </>
                                                    )
                                                }) : ''}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                    </>
                )}
            </div>

            {activeNav.component}

            <LargeImageModal />
        </>
    );
}

export default UserProfile;