import React, { useState } from 'react';
import * as dateFormat from 'date-format'
import FetchData from '../../components/FetchData';
function Coupons() {
    const [filterData, setFilterData] = useState({ process: false, index: null, data: null, action: 'change' });

    const getTable = (i, index) => {
        return (
            <tr key={index}>
                <td>{i.code}</td>
                <td>{i.title}</td>
                <td>{i.description}</td>
                <td>{(i.couponType === '0') ? (<>Flat <i className="fa fa-rupee"></i> </>) : ''}{i.discount}{(i.couponType === '1') ? '%' : '/-'}</td>
                <td>{dateFormat.asString('yyyy-MM-dd', new Date(i.startDate))}</td>
                <td>{dateFormat.asString('yyyy-MM-dd', new Date(i.endDate))}</td>
                <td>{new Date(i.createdAt).toLocaleString()}</td>
            </tr>
        )
    }

    return (
        <>
            <FetchData
                url='coupon/get-coupons'
                title="Coupons"
                subTitle="Coupons List"
                filterData={filterData}
                setFilterData={setFilterData}
                getTable={getTable}
                tableHeading={['Coupon Code', 'Title', 'Description', 'Discount', 'Start Date', 'End Date', 'Created At']} />
        </>
    );
}

export default Coupons;