import React, { useContext, useState } from "react";
import { NavLink } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import './assets/custom.css';
import { success, warning, error as toastrError } from 'toastr';
import { AuthContext } from "../../helper/AuthContext";
import constants from "../../helper/Constants";

function Login({ basePath }) {
    const [otpState, setOtpState] = useState(false);
    const { setAuthStatus, setUserName, setCreatedAt, setUserId } = useContext(AuthContext);
    /** USER LOGIN */
    const initialValues = {
        userName: "",
        password: "",
    };
    const validationSchema = Yup.object().shape({
        userName: Yup.string().trim().required("Username is a required field"),
        password: Yup.string().trim().required("Password is a required field.").min(4, "Password must be at least 4 characters.").max(20, "Password must be at most 20 characters."),
    });

    const onSubmit = (data, form) => {
        constants.serverPost('auth/admin-login', data).then((res) => {
            form.setValues({
                otp: "",
                password: data.password,
                userName: data.userName,
            })
            form.setSubmitting(false);
            if (res.success === '1') {
                setOtpState(true);
                success(res.message);
            }
            else {
                warning(res.message);
            }
        }).catch((error) => {
            toastrError("The server is not responding, please try again later.");
            form.setSubmitting(false);
        });
    }

    /** Verify OTP */
    const otpInitialValues = {
        userName: "",
        password: "",
        otp: "",
    };
    const otpValidationSchema = Yup.object().shape({
        userName: Yup.string().trim().required("Username is a required field"),
        password: Yup.string().trim().required("Password is a required field.").min(4, "Password must be at least 4 characters.").max(20, "Password must be at most 20 characters."),
        otp: Yup.number("Enter 4 digit OTP.").required("Enter 4 digit OTP.").min(1000, "Enter 4 digit OTP.").max(9999, "Enter 4 digit OTP."),
    });

    const otpOnSubmit = (data, { setSubmitting }) => {
        constants.serverPost('auth/admin-login', data).then((res) => {
            if (res.success === '1') {
                localStorage.setItem("authToken", res.authToken);
                success(res.messageDesc, res.message, {
                    timeOut: 5000,
                });
                setUserId(res.data.id);
                setUserName(res.data.userName);
                setCreatedAt(res.data.createdAt);
                setAuthStatus(true);
                window.history.forward();
            }
            else {
                warning(res.message)
                setSubmitting(false);
            }
        }).catch((error) => {
            toastrError("The server is not responding, please try again later.");
            setSubmitting(false);
        });
    }
    return (
        <>
            {(otpState === false) ? (
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {
                        ({ isSubmitting }) => {
                            return (
                                <>
                                    <Form className="login-form validate-form">
                                        <span className="login-form-title"> Member Login </span>
                                        <div className="form-group">
                                            <div className="access-input validate-input">
                                                <Field className="login-input" type="text" name="userName" placeholder="Enter username" />
                                                <span className="input-focus"></span> <span className="input-symbol">
                                                    <i className="fa fa-user" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage className="error" name="userName" component={"small"} />
                                        </div>
                                        <div className="form-group">
                                            <div className="access-input validate-input">
                                                <Field className="login-input" type="password" name="password" placeholder="Password" autoComplete='off' />
                                                <span className="input-focus"></span>
                                                <span className="input-symbol">
                                                    <i className="zmdi zmdi-lock" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage className="error" name="password" component={"small"} />
                                        </div>
                                        <div className="text-right pt-3 pb-3">
                                            {/* <p className="mb-0"><NavLink to={basePath + '/password-reset'} className="text-primary ml-1">Forgot Password?</NavLink></p> */}
                                            <p className="mb-0"><a href="#" className="text-primary ml-1">Forgot Password?</a></p>
                                        </div>
                                        <div className="">
                                            <Field type="submit" disabled={isSubmitting} className="btn btn-primary-light btn-block" value="Login" />
                                        </div>
                                        {/* <div className="text-center pt-3">
                                            <p className="mb-0">Not a member?<NavLink to={basePath + '/register'} className="text-primary ml-1">Sign UP now</NavLink></p>
                                        </div> */}
                                    </Form>
                                </>
                            );
                        }
                    }
                </Formik>
            ) : (
                <Formik initialValues={otpInitialValues} validationSchema={otpValidationSchema} onSubmit={otpOnSubmit}>
                    {
                        ({ isSubmitting }) => {
                            return (
                                <>
                                    <Form className="login-form validate-form">
                                        <span className="login-form-title pb-3"> Verify OTP </span>
                                        <p className="text-center">OTP sent to your registered email address. Please verify otp to start your session.</p>
                                        <div className="form-group">
                                            <div className="access-input validate-input">
                                                <Field className="login-input" type="text" name="otp" placeholder="Enter 4 digit OTP" />
                                                <span className="input-focus"></span> <span className="input-symbol">
                                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage className="error" name="otp" component={"small"} />
                                        </div>
                                        <div className="form-group d-none">
                                            <div className="access-input validate-input">
                                                <Field className="login-input" type="text" name="userName" placeholder="Enter username" />
                                                <span className="input-focus"></span> <span className="input-symbol">
                                                    <i className="fa fa-user" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage className="error" name="userName" component={"small"} />
                                        </div>
                                        <div className="form-group d-none">
                                            <div className="access-input validate-input">
                                                <Field className="login-input" type="password" name="password" placeholder="Password" />
                                                <span className="input-focus"></span>
                                                <span className="input-symbol">
                                                    <i className="zmdi zmdi-lock" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                            <ErrorMessage className="error" name="password" component={"small"} />
                                        </div>
                                        <div className="">
                                            <Field type="submit" disabled={isSubmitting} className="btn btn-primary-light btn-block" value="Verify" />
                                        </div>
                                    </Form>
                                </>
                            );
                        }
                    }
                </Formik>
            )}
        </>
    );
}

export default Login;